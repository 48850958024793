import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { withTheme, makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Upgrade, UpgradeAll } from '../../Common/Buttons';
import Backdrop from "@material-ui/core/Backdrop";
import DeviceConnection from "../../Common/TableCellComponents/DeviceConnection";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchBox from '../../Common/SearchBox';
import { CrudDialog, ContentHeader } from '../../Common';
import { TableGenerator } from "../../Common";
import { CustomerService, ModuleService, VersionService, GroupService, FirmwareService } from "../../../services/Api";
import { useSnackbar } from "notistack";
import { handleServerErrors, handleMultiFilterSearch, mapOrder } from '../../../helpers';
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { dateFormatter } from 'utils/helpers';

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: 10000,
        color: "#fff"
    },
    loader: {
        position: "fixed",
        top: "50%",
        left: "50%",
        zIndex: 100000
    }
}));

const Loader = () => {
    const classes = useStyles();
    return (
        <div>
            <Backdrop className={classes.backdrop} open={true} />
            <CircularProgress className={classes.loader} color="primary" />
        </div>
    );
};

const filterLabels = {
    serial_number: 'serial_number',
    vm_name: "vm_name",
    operator: "company",
    device_id: 'oro_id',
    device_type: 'device_type',
    main_app_version: 'main_app_version',
    watchdog_version: 'watchdog_version',
    hw_service_version: 'hw_service_version'

};

const firmwareFields = [
    'serial_number',
    'vm_name',
    'operator',
    'device_id',
    'device_type',
    'main_app_version',
    'watchdog_version',
    'hw_service_version'
];

const Firmware = (props) => {
    const classes = styles();
    const current_user = useSelector((state) => state.userReducer.current_user);

    const [versionList, setVersionList] = useState([]);
    const [modulesList, setModulesList] = useState([]);
    const [upgradeValue, setUpgradealue] = useState()
    const [groups, setGroups] = useState();
    const [dataCount, setDataCount] = useState(0);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [ordering, setOrdering] = useState('-updated_at');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [all, setAll] = useState();
    const [upgrade, setUpgradeModal] = useState(false);
    const [upgradeGroupModal, setUpgradeGroupModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [upgradeAll, setUpgradeAll] = useState(false);
    const [groupList, setGroupList] = useState([]);
    const [modulesSelected, setModulesSelected] = useState([]);
    const [loader, setLoader] = useState(false);
    const [groupConfirmModal, setGroupConfirmModal] = useState(false);
    const [allConfirmModal, setAllConfirmModal] = useState(false);
    const [query, setQuery] = useState("");
    const [upgradeLoader, setUpgradeLoader] = useState(false);
    const [filterable, setFilterable] = useState(firmwareFields);
    const { enqueueSnackbar } = useSnackbar();
    const [deviceTypeList, setDeviceTypeList] = useState([]);
    const [deviceType, setDeviceType] = useState("");
    const [searchQuery, setSearchQuery] = useState({});
    const [versionType,setVersionType] = useState("");
    const [controller, setController] = useState(null);
    const [paymentOptions,setPaymentOptions] = useState(null);
    const [selectedDeviceTypes, setSelectedDeviceTypes] = useState([]);
    const [showCrudDialogSubmitLoader, setShowCrudDialogSubmitLoader] = useState(false);

    const isValidDate = (d) => {
        return d instanceof Date && !isNaN(d);
    };
    
    const fields = [
        { key: 'serial_number', columnName: 'Serial number', label: 'Serial number', type: 'text', required: true, visible: true, form: false},
        { key: 'vm_name', columnName: 'VM Name', label: 'VM Name', type: 'text',  visible: true, form: false,render: (value) => value ?? '---' },
        {
            key: "company",
            columnName: "Operator",
            label: "Operator",
            type: "text",
            visible: true,
            form: false,
            render: (value) => {
              return value?.business_name || "---";
            },
          },        
          {
            key: "status",
            columnName: "Network Status",
            type: "text",
            required: false,
            visible: true,
            form: false,
            render: (value) => <DeviceConnection status={value} />,
          },
        { key: 'oro_id', columnName: 'Device ID', label: 'Device ID', type: 'text', required: true, visible: true, form: false },
        {
            key: "device_type",
            disabled: upgrade === true,
            label: "Device Type",
            columnName: "Device Type",
            type: "multiAutoComplete",
            multiple: true,
            form:true,
            freeSolo: false,
            options: [
              ...deviceTypeList.map((x) => ({ label: x.device_type, value: x.id })),
            ],
            required: true,
            visible: true,
            render: (value, record) => record.device_type || "---",    
            show: true,
            validations: [{ type: "required", value: true }],
            disableClearable: true,
            maxTags: selectedDeviceTypes?.length || 2
          },
          {
            key: "controller",
            label: "Controller",
            columnName: "Controller",
            type: "select",
            form:true,
            options: [
              { label: "C31", value: "C31" },
              { label: "InHand", value: "InHand" },
            ],
            required: true,
            visible: true,
            render: (value) => value || "---",
            unclosable: true
          },
          {
            key: "version_type",
            label: "Version Type",
            columnName: "Version Type",
            type: "select",
            form:true,
            options: selectedDeviceTypes?.length > 1 ?
                [
                    { label: "Main App", value: "MainApp" },
                    { label: "Watch Dog", value: "WatchDog" }
                ] :
                [
                    { label: "Main App", value: "MainApp" },
                    { label: "Watch Dog", value: "WatchDog" },
                    { label: "VM Services", value: "VMServices" }
                ],
            required: true,
            visible: true,
            render: (value) => value || "---",
            unclosable: true
          },
          {
            key: "payment_options",
            label: "Payment Option",
            columnName: "Payment Option",
            type: "select",
            form:true,
            options: [
              { label: "Nayax", value: "Nayax" },
              { label: "Magtek (TSYS)", value: "Magtek (TSYS)" },
              { label: "Magtek (CHASE)", value: "Magtek (CHASE)" },
            ],
            required: true,
            visible: true,
            render: (value) => value || "---",
            unclosable: true
          },
        { key: 'versions', columnName: 'FW Version', type: 'autocomplete', label: 'Version Name', form: true, disableClearable: true, freeSolo: false, show: true, required: true, visible: true, optionStyle: {fontSize: 12},
        // options: [...versionList.filter(x => x.device_type === (modulesSelected[0] || {}).device_type_id).map(x => ({ label: `${x.name} || ${x.version_type}`,
        //  value: x.id }))] },
         options: [...versionList.map(x => {
             if (isValidDate(new Date(x.created_at))) {
                 if (x?.device_type_name) {
                     return ({ label: `${x.name} || ${x.version_type} || ${x.device_type_name} || ${dateFormatter(x.created_at, true)}`, value: x.id })
                 } else {
                     return ({ label: `${x.name} || ${x.version_type} || ${dateFormatter(x.created_at, true)}`, value: x.id })
                 }
             } else {
                 if (x?.device_type_name) {
                     return ({ label: `${x.name} || ${x.version_type} || ${x.device_type_name}`, value: x.id })
                 } else {
                     return ({ label: `${x.name} || ${x.version_type}`, value: x.id })
                 }
             }
         }
            )] },

        // { key: 'version_type',  label: 'Version Type', columnName: 'Version Type', type: 'select', options: [{ label: 'Main App', value: 'MainApp' },{ label: "Watch Dog", value: "WatchDog" },{ label: "VM Services", value: "VMServices" }], required: true, visible: true, render: (value) => value?.version_type || '---'},
         { key: 'updated_at', columnName: 'Updated at', label: 'Updated at', type: 'text', required: true, visible: true, form: false,render: (value) => moment(value).format("MM-DD-YYYY hh:mm:ss A")},
    ];
    let modalFields=[...fields];
    if(versionType!=="VMServices"){
      modalFields= [...fields].filter((x) => x.key !== "payment_options");
    }
    const groupfields = [
        { key: 'serial_number', columnName: 'Serial number', label: 'Serial number', type: 'text', required: true, visible: true, form: false },
        { key: 'oro_id', columnName: 'Device ID', label: 'Device ID', type: 'text', required: true, visible: true, form: false },
        { key: 'groups', columnName: 'Group Name', label: 'Group Name', options: [...groupList.map(x => ({ label: x.name, value: x.id }))], type: 'select', required: true, visible: true, render: (value) => value ? value.name : '---' },
        {
            key: 'versions', columnName: 'FW Version', type: 'select', label: 'Version Name', required: true, visible: true, options: [...versionList.map(x => ({ label: `${x.versionId}, ${x.name}`, value: x.id }))],
            render: (value) => { return (value || {}).versionId }
        },
    ];

    const allFields = [
        { key: 'serial_number', columnName: 'Serial number', label: 'Serial number', type: 'text', required: true, visible: true, form: false },
        { key: 'oro_id', columnName: 'Device ID', label: 'Device ID', type: 'text', required: true, visible: true, form: false },
        { key: 'device_type', label: 'Device Type', columnName: 'Device Type', type: 'select', options: [...deviceTypeList.map(x => ({ label: x.device_type, value: x.id }))], required: true, visible: true, render: (value, record) => record.device_type || '---', validations: [{ type: 'required', value: true }] },
        { key: 'versions', columnName: 'FW Version', type: 'select', label: 'Version Name', required: true, visible: true, options: [...versionList.filter(x => x.device_type === deviceType).map(x => ({ label: `${x.versionId}, ${x.name}`, value: x.id }))], render: (value) => { return (value || {}).versionId } },
    ];

    const tableFields = [
        {
            key: 'serial_number',
            columnName: 'Serial number',
            label: 'Serial number',
            type: 'text',
            required: true,
            visible: true,
            form: false
        },
        {
            key: 'vm_name',
            columnName: 'VM Name',
            label: 'VM Name',
            type: 'text',
            visible: true,
            form: false,
            render: (value) => value ?? '---'
        },
        {
            key: "company",
            columnName: "Operator",
            label: "Operator",
            type: "text",
            visible: true,
            form: false,
            render: (value) => {
                return value?.business_name || "---";
            },
        },
        {
            key: "status",
            columnName: "Network Status",
            type: "text",
            required: false,
            visible: true,
            form: false,
            render: (value) => <DeviceConnection status={value} />,
        },
        {
            key: 'oro_id',
            columnName: 'Device ID',
            label: 'Device ID',
            type: 'text',
            required: true,
            visible: true,
            form: false
        },
        {
            key: "device_type",
            disabled: upgrade === true,
            label: "Device Type",
            columnName: "Device Type",
            type: "multiAutoComplete",
            multiple: true,
            form: true,
            freeSolo: false,
            options: [
                ...deviceTypeList.map((x) => ({ label: x.device_type, value: x.id })),
            ],
            required: true,
            visible: true,
            render: (value, record) => record.device_type || "---",
            show: true,
            validations: [{ type: "required", value: true }],
            disableClearable: true,
            maxTags: selectedDeviceTypes?.length || 2
        },
        {
            key: "payment_gateway",
            label: "Payment Option",
            columnName: "Payment Option",
            type: "text",
            form: false,
            visible: true,
            render: (value) => value || "---",
        },
        {
            key: "controller",
            label: "Controller",
            columnName: "Controller",
            type: "text",
            form: false,
            visible: true,
            render: (value) => value || "---",
        },
        {
            key: "main_app_versionid",
            columnName: "Main App Version",
            type: "text",
            form: false,
            visible: true,
            disableSorting: true,
            render: (_, value) => value?.versionsData?.main_app_versionid || "---"
        },
        {
            key: "watchdog_versionid",
            columnName: "Watchdog Version",
            type: "text",
            form: false,
            visible: true,
            disableSorting: true,
            render: (_, value) => value?.versionsData?.watchdog_versionid || "---"
        },
        {
            key: "hw_service_versionid",
            columnName: "HW Service Version",
            type: "text",
            form: false,
            required: true,
            visible: true,
            disableSorting: true,
            render: (_, value) => {
             return value?.versionsData?.hw_service_versionid || "---"
            }
        },
        {
            key: 'updated_at',
            columnName: 'Updated at',
            label: 'Updated at',
            type: 'text',
            visible: true,
            form: false,
            render: (value) => moment(value).format("MM-DD-YYYY hh:mm:ss A")
        },
    ]

    const setup =  () => {
        setLoader(true);
        setModulesList([]);
        setModulesSelected([])
    }

    const handleRes = (data) => {
        let newData = (data.results || []).map(val => ({
            ...val,
            status: val.status ? "Connected" : "Not Connected",
            versions: (val.versions || {}).versionId ? val.versions.versionId : "",
            versionsData: (val.versions || {})
            // version: (val.versions || {}).versionId ? val.versions.versionId : ""
        }));
        setModulesList(newData)
        setNextPage(data.next);
        setPreviousPage(data.previous);
        setFirstPage(data.first);
        setLastPage(data.last);
        setLoader(false);
        setDataCount(data.count);
    }

    const handleSearch = (value) => {
        setQuery(value);
        let searchFilter = {};
        if (value !== "") {
            searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
        }
        setSearchQuery(searchFilter);
        setup();
        setPage(0);
        ModuleService.search({ ...searchFilter, limit: rowsPerPage, ordering: mapOrder(ordering) })
            .then(({ data = {} }) => {
                handleRes(data);
            }).catch(err => {
                setLoader(false);
            })
    };

    const changePage = (url) => {
        setup();
        window.axiosIns.get(url).then(({data = {}}) => {
            handleRes(data.data);
        }).catch(err => {
            setLoader(false);
            if (err.detail) {
                enqueueSnackbar(err.detail);
            }
            else {
                handleServerErrors(err, enqueueSnackbar, "Could not get firmware. Try again.");
            }
        });
    }

    const getCategory = () => {
        CustomerService.getCategory({all: true, ordering: "category_name"})
            .then(({ data = {} }) => {
                setDeviceTypeList(data.results);
            }).catch(err => {
                handleServerErrors(err, enqueueSnackbar, "Could not get categories. Try again.");
            });
    };

    const handleFilter = (arr) => {
        setFilterable(arr);
        if (query !== "") {
            let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
            setup();
            setPage(0);
            ModuleService.search({...searchFilter, limit: rowsPerPage, ordering: mapOrder(ordering)})
                .then(({ data = {} }) => {
                    handleRes(data);
                }).catch(err => {
                    setLoader(false);
                })
        } 
    };

    const getModules = (order, max, customPage = page) => {
        const params = {
            ...searchQuery,
            limit: max? max: rowsPerPage,
            ordering: order? mapOrder(order): mapOrder(ordering),
            page: customPage + 1,
        }
        setup();
        ModuleService.modules(params)
            .then(({ data = {} }) => {
                handleRes(data);
            }).catch(err => {
                setLoader(false);
                if (err.response) {
                    if (err.response.data.detail) {
                        enqueueSnackbar(err.response.data.detail)
                    }
                    else {
                        handleServerErrors(err, enqueueSnackbar, "Could not get firmware. Try again.");
                    }
                }
            });
    };


    const getGroups = () => {
        GroupService.groups({all: true})
            .then(({ data = {} }) => {
                setGroupList(data.results);
            }).catch(err => {
                if (err.response.data.detail) {
                    enqueueSnackbar(err.response.data.detail)
                }
                else {
                    handleServerErrors(err, enqueueSnackbar, "Could not get groups. Try again.");
                }
            });
    };

    const getVersions = (paymentOption) => {
        setVersionList([]);
        let params = {
            all: true,
            ordering: "name",
            available_for_operators: current_user?.company?.company_id || "",
            controller: controller,
            version_type: versionType,
            payment_options: paymentOption,
            ordering: "-created_at"
        }
        if (versionType == "VMServices") {
            params.device_type_ids = selectedDeviceTypes.join(",");
        }
        VersionService.list(params).then(({ data = {} }) => {
            setVersionList(data.results);
        })
    };

    useEffect(() => {
        getModules();
        getGroups();
        getCategory();
    }, []);

    useEffect(() => {
        if (deviceType && versionType && controller) {
            if (versionType !== "VMServices") {
                getVersions(null);
            } else if (paymentOptions) {
                getVersions(paymentOptions);
            }         
        }
    }, [deviceType, versionType, controller, paymentOptions])

    const handleEdit = () => {
        const v = modulesSelected.map(val => val.oro_id)
        setShowCrudDialogSubmitLoader(true);
        ModuleService.upgradeModule({
            devices: v,
            version: upgradeValue.versions
        })
            .then((res) => {
                enqueueSnackbar(res?.data);
                setShowCrudDialogSubmitLoader(false);
                setConfirmModal(false);
                setModulesList([]);
                setModulesSelected([]);
                getModules();
            }).catch(err => {
                setShowCrudDialogSubmitLoader(false);
                if (err.detail) {
                    enqueueSnackbar(err.detail);
                }
                else {
                    handleServerErrors(err, enqueueSnackbar, "Could not edit firmware. Try again.");
                }
            });
    };

    const handleUpgradeGroup = () => {
        setUpgradeLoader(true);
        FirmwareService.upgradeGroup({
            version_id: groups.versions,
            groups: groups.groups
        })
            .then(() => {
                setTimeout(() => {
                    setUpgradeLoader(false);
                    enqueueSnackbar("Firmwares Upgraded successfully.");
                }, 3000)
            }).catch(err => {
                if (err.detail) {
                    enqueueSnackbar(err.detail);
                }
                else {
                    handleServerErrors(err, enqueueSnackbar, "Could not upgrade. Try again.");
                }
                setUpgradeLoader(false);
            }).then(() => {
                getModules();
                setModulesSelected([]);
            })
    }

    const handleUpgradeAll = () => {
        setUpgradeLoader(true);
        FirmwareService.upgradeAll({
            version_id: all.versions,
            all:true
            // device_type: all.device_type
        })
            .then(() => {
                setTimeout(() => {
                    setUpgradeLoader(false);
                    enqueueSnackbar("Firmwares Upgraded successfully.");
                }, 3000)
            }).catch(err => {
                setUpgradeLoader(false);
                if (err.detail) {
                    enqueueSnackbar(err.detail);
                }
                else {
                    handleServerErrors(err, enqueueSnackbar, "Could not upgrade all. Try again.");
                }
            }).then(() => {
                getModules();
                setModulesSelected([]);
            })
    }

    const getDeviceTypesOfSelected = (selectedItems, isReturn = false) => {
        const deviceTypes = [...new Set(selectedItems.map(item => Number(item.device_type_id)))];
        if (isReturn) {
            return deviceTypes;
        } else {
            setSelectedDeviceTypes(deviceTypes);
        }
    }

    return (
        <div id="sa-modules-wrapper" className={classes.wrapper}>
            <ContentHeader title="" description="One Click upgrade of the firmware of the selected devices and groups." />
            <div className={classes.toolbar}>
                <div className={classes.crudButtons}>
                    {/* <Upgrade disabled={modulesSelected.length !== 1 || props.currentUser.type === 'SU'} className="mr-3" label="Upgrade" onClick={() => setUpgradeModal(true)} /> */}
                    <Upgrade disabled={modulesSelected.length <= 0 || props.currentUser.type === 'SU' || !modulesSelected.every((entry) =>
                    entry?.status === 'Connected' || entry?.status === true )} className="mr-3" label="Upgrade" onClick={() => setUpgradeModal(true)} />
                    {/* <UpgradeAll disabled={props.currentUser.type === 'SU'} className="mr-3" label="Upgrade all" onClick={() => setUpgradeAll(true)} /> */}
                </div>
                <SearchBox multiple={true} query={query} onChange={handleFilter} fields={firmwareFields} selectedFields={filterable} handleSearch={handleSearch} />
            </div>
            <div className={classes.content}>
                <TableGenerator
                    searchQuery={query}
                    searchColumnsFilter={true}
                    fields={tableFields}
                    data={modulesList}
                    loader={loader}
                    onChangePage={(page) => console.log(page)}
                    selectedRecords={modulesSelected}
                    initialSort={'device_type'}
                    backendPagination={true}
                    currentPage={page}
                    handleSortChange={(ordering) => {
                        setOrdering(ordering)
                        getModules(ordering)
                    }}
                    onPageChange={(page, direction) => {
                        setPage(page)
                        if(direction === 'next') {
                            changePage(nextPage);
                        } else if(direction === 'back') {
                            changePage(previousPage)
                        } else if(direction === 'first') {
                            changePage(firstPage)
                        } else if(direction === 'last') {
                            changePage(lastPage)
                        }
                    }}
                    onRowPerPageChange={(rows) => {
                        getModules(null, rows, 0);
                        setRowsPerPage(rows);
                        setPage(0);
                    }}
                    dataCount={dataCount}
                    rowOnePage={10}
                    onChangeSelected={(modulesSelected) => {
                        setModulesSelected(modulesSelected);
                        setDeviceType(modulesSelected[0]?.device_type || "");
                        getDeviceTypesOfSelected(modulesSelected);
                    }}
                />
                <CrudDialog
                    title="Upgrade"
                    okText="Update Device"
                    description="Please select the version to upgrade."
                    fields={modalFields}
                    values={{...modulesSelected[0], device_type: getDeviceTypesOfSelected(modulesSelected, true), controller: ""}}
                    onFieldChange={(field, value) => {
                        if (field?.key === "version_type") {
                            setVersionType(value);
                        }
                        if (field?.key === "device_type") {
                            setDeviceType(
                              deviceTypeList.filter((x) => x.id == value)[0]?.device_type
                            );
                        }
                        if (field?.key === "controller") {
                            setController(value);
                        }
                        if (field?.key === "payment_options") {
                            setPaymentOptions(value);
                        }
                      }}
                    onSubmit={(values, hasErrors) => {
                        setUpgradealue(values);
                        setUpgradeModal(false);
                        setConfirmModal(true);
                        setController(null);
                        setVersionType("");
                        setPaymentOptions(null);
                        setVersionList([]);
                    }}
                    open={upgrade}
                    onClose={() => {
                        setUpgradeModal(false);
                        setController(null);
                        setVersionType("");
                        setPaymentOptions(null);
                        setVersionList([]);
                    }}
                />
                <CrudDialog
                    title="Upgrade Group"
                    okText="Upgrade Group"
                    description="Please select the version to upgrade."
                    fields={groupfields}
                    values={modulesSelected[0]}
                    onSubmit={(values, hasErrors) => {
                        const { groups, versions } = values;
                        setGroups(values);
                        if (groups && versions) {
                            setUpgradeGroupModal(false)
                            setGroupConfirmModal(true)
                        }
                        else {
                            enqueueSnackbar("Please fill required fields.");
                        }
                    }}
                    open={upgradeGroupModal}
                    onClose={() => {
                        setGroups('')
                        setUpgradeGroupModal(false)
                    }}
                />
                <CrudDialog
                    onFieldChange={(field, value) => {
                        if (field.key === "device_type") {
                            setDeviceType(value)
                        }
                    }}
                    title="Upgrade All"
                    okText="Upgrade All"
                    description="Please select the version and device type to upgrade."
                    fields={allFields}
                    values={modulesSelected[0]}
                    onSubmit={(values, hasErrors) => {
                        const { versions } = values;
                        setAll(values);
                        if (versions) {
                            setUpgradeAll(false)
                            setAllConfirmModal(true)
                        }
                        else {
                            enqueueSnackbar("Please fill required fields.");
                        }
                    }}
                    open={upgradeAll}
                    onClose={() => {
                        setUpgradeAll(false)
                        setAllConfirmModal(false)
                    }}
                />
                <CrudDialog
                    title="Upgrade won't affect disconnected device(s). Do you want to proceed upgrading connected device(s)?"
                    okText="Yes"
                    onSubmit={() => {
                        handleEdit()
                        setUpgradeModal(false)
                    }}
                    open={confirmModal}
                    onClose={() => {
                        setConfirmModal(false);
                        setUpgradeModal(false);
                    }}
                    showSubmitActionLoader={showCrudDialogSubmitLoader}
                />
                <CrudDialog
                    title="Are you sure?"
                    okText="Yes"
                    onSubmit={(e) => {
                        handleUpgradeGroup()
                        setUpgradeGroupModal(false)
                        setGroupConfirmModal(false)
                    }}
                    open={groupConfirmModal}
                    onClose={() => {
                        setGroupConfirmModal(false)
                        setUpgradeGroupModal(true)
                    }}
                />
                <CrudDialog
                    title="Upgrade won't affect disconnected devices. Do you want to proceed upgrading connected devices?"
                    okText="Yes"
                    onSubmit={() => {
                        handleUpgradeAll();
                        setUpgradeAll(false)
                        setAllConfirmModal(false)
                    }}
                    open={allConfirmModal}
                    onClose={() => {
                        setAllConfirmModal(false)
                        setUpgradeAll(true)
                    }}
                />
                {upgradeLoader && <Loader />}
            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.current_user
    }
};

export default compose(
    withTheme,
    connect(mapStateToProps)
)(Firmware);


