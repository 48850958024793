import React, { useEffect, useState } from "react";
import { withTheme } from "@material-ui/core/styles";
import styles from "./styles";
import { AddButton, EditButton, DeleteButton } from "../../Common/Buttons";
import SearchBox from "../../Common/SearchBox";
import { CrudDialog, ContentHeader } from "../../Common";
import { TableGenerator } from "../../Common";
import { VersionService, CustomerService } from "../../../services/Api";
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
  mapOrder,
} from "../../../helpers";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";

const filterLabels = {
  version_id: "versionId",
  device_type: "device_type",
  name: "name",
  description: "description",
};

const versionFields = ["version_id", "device_type", "name", "description"];

const Versions = (props) => {
  const classes = styles();
  const [versionList, setVersionList] = useState([]);
  const [deviceTypeList, setDeviceTypeList] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("name");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedVersions, setSelectedVersions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [query, setQuery] = useState("");
  const [filterable, setFilterable] = useState(versionFields);
  const [versionType,setVersionType] = useState("");
  const [showCrudDialogSubmitLoader, setShowCrudDialogSubmitLoader] =
    useState(false);
  const [operatorsList, setOperatorsList] = useState([]);
  const [searchParams, setSearchParams] = useState({})

  const { enqueueSnackbar } = useSnackbar();
  const versionTypeOptions = [
    { label: "Main App", value: "MainApp" },
    { label: "Watch Dog", value: "WatchDog" },
    { label: "VM Services", value: "VMServices" },
  ];
  let fields = [
    {
      key: "versionId",
      disabled: editModal === true,
      columnName: "Version ID",
      label: "Version ID",
      type: "text",
      required: true,
      visible: true,
    },
    {
      key: "version_type",
      label: "Version Type",
      columnName: "Version Type",
      disabled: editModal === true,
      type: "select",
      options: versionTypeOptions,
      required: true,
      visible: true,
      render: value => versionTypeOptions?.filter(item=>item.value == value)?.[0]?.label || "---",
    },
    {
      key: "device_type",
      disabled: editModal === true,
      label: "Device Type",
      columnName: "Device Type",
      type: "select",
      options: [
        ...deviceTypeList.map((x) => ({ label: x.device_type, value: x.id })),
      ],
      required: versionType != "MainApp" ? true : false,
      visible: versionType != "MainApp" ? true : false,
      render: (value, record) => {
       const deviceType =  deviceTypeList.find(item => item.id === record.device_type);
       return deviceType?.device_type || "---";
      },
      validations: [{ type: "required", value: true }],
    },
    {
      key: "name",
      columnName: "Name",
      label: "Name",
      type: "text",
      required: true,
      visible: true,
    },
    {
      key: "controller",
      label: "Controller",
      disabled: editModal === true,
      columnName: "Controller",
      type: "select",
      options: [
        { label: "C31", value: "C31" },
        { label: "InHand", value: "InHand" },
      ],
      required: true,
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "payment_options",
      label: "Payment Option",
      columnName: "Payment Option",
      type: "select",
      disabled: editModal === true,
      options: [
        { label: "Nayax", value: "Nayax" },
        { label: "Magtek (TSYS)", value: "Magtek (TSYS)" },
        { label: "Magtek (CHASE)", value: "Magtek (CHASE)" },
      ],
      required: true,
      visible: true,
      render: (value) => value || "---",
    },
    // { key: 'version_type', disabled: editModal === true, label: 'Version Type', columnName: 'Version Type', type: 'select', options: [...deviceTypeList.map(x => ({ label: x.device_type, value: x.id }))], required: true, visible: true, render: (value, record) => record.device_type_name || '---', validations: [{ type: 'required', value: true }] },
    {
      key: "description",
      columnName: "Description",
      label: "Description",
      type: "text",
      required: true,
      visible: true,
    },
    {
      key: "available_for_operators",
      label: "Operators",
      columnName: "Operators",
      type: "select",
      options: [
        ...operatorsList.map((x) => ({ label: x.business_name, value: x.id })),
      ],
      required: true,
      visible: true,
      multiple: true,
      render: (value, record) => (record?.operator_details || []).map(item=>item?.business_name)?.join(', ') || "---",
    },
    {
      key: "package",
      columnName: "File Package",
      label: "File Package",
      disabled: editModal === true,
      type: "file",
      required: true,
      visible: true,
      table: false,
      fileTypeAllowed:"apk",
      hint: "Upload the package containing device firmware.",
    },
    { key: 'created_at', columnName: 'Created at', label: 'Created at', type: 'text', required: true, visible: true, form: false,render: (value) => moment(value).format("MM-DD-YYYY hh:mm:ss A")},
  ];
  let modalFields=[...fields];
  if((addModal && versionType!=="VMServices") || (editModal && (selectedVersions && selectedVersions[0] && selectedVersions[0].version_type && selectedVersions[0].version_type !=="VMServices"))){
    modalFields= [...fields].filter((x) => x.key !== "payment_options");

  }
  const setup = () => {
    setLoader(true);
    setVersionList([]);
    setSelectedVersions([]);
  };

  const handleRes = (data) => {
    setVersionList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {}
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(
        filterLabels,
        filterable,
        value
      );
    }
    setup();
    setPage(0);
    setSearchParams(searchFilter)
    VersionService.list({
      ...searchFilter,
      limit: rowsPerPage,
      ordering: mapOrder(ordering),
    })
      .then((response = {}) => {
        handleRes(response.data);
      })
      .catch((err) => {
        setLoader(false);
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Could not get versions. Try again."
        );
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      VersionService.list({
        ...searchFilter,
        limit: rowsPerPage,
        ordering: mapOrder(ordering),
      })
        .then((response = {}) => {
          handleRes(response.data);
        })
        .catch((err) => {
          setLoader(false);
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get versions. Try again."
          );
        });
    }
  };

  const getFactories = (order, max, customPage = page) => {
    const params = {
      ...searchParams,
      limit: max ? max : rowsPerPage,
      ordering: order ? mapOrder(order) : mapOrder(ordering),
      page: customPage + 1,
    };
    setup();
    VersionService.list(params)
      .then((response = {}) => {
        handleRes(response.data);
      })
      .catch((err) => {
        setLoader(false);
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Could not get versions. Try again."
        );
      });
  };

  const getCategory = () => {
    CustomerService.getCategory({ all: true, ordering: "category_name" })
      .then(({ data = {} }) => {
        setDeviceTypeList(data.results);
      })
      .catch((err) => {
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Could not get categories. Try again."
        );
      });
  };

  const getOperators = () => {
    CustomerService.getOperators({ ordering: "business_name", all: true, lite: true })
      .then(({ data = {} }) => {
        setOperatorsList(data.results);
      })
      .catch((err) => {
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Could not get operators. Try again."
        );
      });
  };
  
  useEffect(() => {
    getFactories();
    getCategory();
    getOperators();
  }, []);

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get versions. Try again."
          );
        }
      });
  };

  const handleAdd = (data, err) => {
    if (err) {
      return;
    }
    if(!(data?.available_for_operators && data.available_for_operators.length>=1)){
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Please select atleast one operator."
      );
      return;
    }
   
    if(data?.package){
      setShowCrudDialogSubmitLoader(true);
      const fd = new FormData();
      fd.append("versionId", data.versionId);
      fd.append("name", data.name);
      if (data?.version_type != "MainApp") {
        fd.append("device_type", data.device_type);
      }
      fd.append("version_type", data.version_type);
      fd.append("description", data.description);
      fd.append("controller", data.controller || "");
      fd.append("package", data.package || "");
      fd.append("available_for_operators",JSON.stringify( data?.available_for_operators || []));
      if(data?.payment_options && data.payment_options !== "" && data.version_type === "VMServices"){
        fd.append("payment_options", data.payment_options);
      }
      VersionService.create(fd)
        .then(() => {
          getFactories();
          setAddModal(false);
          setVersionType('')
          enqueueSnackbar("Version created successfully.");
          setSelectedVersions([]);
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not add versions. Try again."
            );
          }
        })
        .finally(() => {
          setShowCrudDialogSubmitLoader(false);
        });
    }else{
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Please upload a file package."
      );
    }
    
  };

  const handleEdit = (data) => {
    setShowCrudDialogSubmitLoader(true);
    const fd = new FormData();
    fd.append("versionId", data.versionId);
    fd.append("name", data.name);
    fd.append("description", data.description);
    fd.append("version_type", data?.version_type);
    fd.append("available_for_operators",JSON.stringify( data?.available_for_operators || []));

    VersionService.update(selectedVersions[0].id, fd)
      .then(() => {
        setQuery("");
        setVersionList([]);
        getFactories();
        setSelectedVersions([]);
        setEditModal(false);
        enqueueSnackbar("Version edited successfully.");
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit versions. Try again."
          );
        }
      })
      .finally(() => {
        setShowCrudDialogSubmitLoader(false);
      });
  };

  const handleDelete = () => {
    setShowCrudDialogSubmitLoader(true);
    selectedVersions.forEach((val, index) => {
      VersionService.delete({ id: val.id })
        .then(() => {
          setSearchParams({});
          if (index + 1 === selectedVersions.length) {
            if (
              versionList.length - selectedVersions.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getFactories();
            }
            setShowCrudDialogSubmitLoader(false);
            enqueueSnackbar("Version(s) deleted successfully.");
            setDeleteModal(false);
            setQuery("");
          }
        })
        .catch((err) => {
          setShowCrudDialogSubmitLoader(false);
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete Version(s). Try again."
            );
          }
        });
    });
  };
  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader title="" description="Maintain all Firmware versions." />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            disabled={props.currentUser.type === "SU"}
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
          />
          <EditButton
            disabled={
              selectedVersions.length !== 1 ||
              props.currentUser.type === "SU" ||
              (props.currentUser.type !== "SA" &&
                selectedVersions[0].created_by !== props.currentUser.id)
            }
            className="mr-3"
            label="Edit"
            onClick={() => {
              setSearchParams({})
              setEditModal(true)
            }}
          />
          <DeleteButton
            disabled={
              selectedVersions.length === 0 ||
              props.currentUser.type === "SU" ||
              (props.currentUser.type !== "SA" &&
                selectedVersions.filter(
                  (x) => x.created_by !== props.currentUser.id
                ).length)
            }
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
        </div>
        <SearchBox
          multiple={true}
          query={query}
          onChange={handleFilter}
          fields={versionFields}
          selectedFields={filterable}
          handleSearch={handleSearch}
        />
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          searchColumnsFilter={true}
          fields={fields.filter((x) => x.table !== false)}
          data={versionList}
          loader={loader}
          initialSort={"name"}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getFactories(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          onRowPerPageChange={(rows) => {
            getFactories(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          backendPagination={true}
          selectedRecords={selectedVersions}
          rowOnePage={10}
          onChangeSelected={(selectedVersions) =>
            setSelectedVersions(selectedVersions)
          }
        />
        <CrudDialog
          title="Add Firmware Version"
          okText="Add Version"
          description="Please fill in the details below."
          fields={modalFields}
          onSubmit={(values, err) => {
            handleAdd(values, err);
          }}
          onFieldChange={(field, value) => {
            if(field?.key==="version_type"){
              setVersionType(value)
            }
          }}
          open={addModal}
          onClose={() => {
            setAddModal(false);
            setVersionType('')
          }}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
        <CrudDialog
          title="Edit Firmware Version"
          okText="Save"
          description="Please edit the details below."
          fields={
            editModal === true
              ? modalFields.filter((x) => x.key !== "package")
              : modalFields
          }
          values={selectedVersions[0]}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
          onFieldChange={(field, value,fieldValues) => {
            if(field?.available_for_operators === "" || !field?.available_for_operators){
              const copiedArray = [...selectedVersions];
              copiedArray[0].available_for_operators = [];
              setSelectedVersions(copiedArray);
            }else{
              setSelectedVersions([...selectedVersions,...field]);
            }
          }}
          onOpen={() => {
            setVersionType(selectedVersions[0]?.version_type);
          }}
        />
        <CrudDialog
          title="Delete Version(s)"
          description="Are you sure you want to delete the Version(s)?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
  };
};

export default compose(withTheme, connect(mapStateToProps))(Versions);
