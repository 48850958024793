import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import styles from "./styles";
import {
  AddButton,
  EditButton,
  DeleteButton,
  AllocateButton,
  RefreshButton,
  ExportButton,
  StatusUpdateButton,
  SettingsButton
} from "../../Common/Buttons";
import SearchBox from "../../Common/SearchBox";
import { CrudDialog, ContentHeader } from "../../Common";
import { TableGenerator } from "../../Common";
import DeviceConnection from "../../Common/TableCellComponents/DeviceConnection";
import WorkingStatus from "../../Common/TableCellComponents/WorkingStatus";
// import { ImportButton } from "components/Common/Buttons";
// import * as XLSX from 'xlsx';
import {
  CustomerService,
  ModuleService,
  VersionService,
  WorkingStatusService,
} from "../../../services/Api";
import { useSnackbar } from "notistack";
import { Button, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import {
  handleServerErrors,
  handleMultiFilterSearch,
  downloadBlob,
} from "../../../helpers";
import moment from "moment";
import { connect } from "react-redux";
import _, { map } from "lodash";
import { useHistory } from "react-router-dom";
import { GetApp, Kitchen } from "@material-ui/icons";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import ResetIcon from "@material-ui/icons/RotateLeft";
import QRCode from "react-qr-code";
import { dateFormatter, taxFormatter } from "utils/helpers";
import { domainLevelConfig } from "../../../ui-config";
import { getTimeZoneDifference } from "utils/helpers";
import InfoModal from "components/Common/AlertDialog/info_dialogue";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 13,
    // width:900,
    // height:50
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow placement="left" classes={classes} {...props} />;
}

const WorkingStatusFields = [
  [
    {
      key: "working_status",
      label: "Status",
      columnName: "status",
      type: "radio",
      show: true,
      options: [
        {
          value: "inService",
          label: "In Service",
        },
        {
          value: "outOfService",
          label: "Out of Service",
        },
      ],
      required: true,
      visible: true,
    },
  ],
];

const approveStatusFields = [
  [
    {
      key: "approval_status",
      label: "Status",
      columnName: "Status",
      direction: "column",
      type: "radio",
      show: true,
      border: true,
      style: {
        margin: 5,
        height: 45,
      },
      defaultValue: "",
      options: [
        {
          value: "Approved",
          label: "Approve",
        },
        {
          value: "Denied",
          label: "Deny",
        },
        {
          value: "Revoked",
          label: "Revoke",
        }
      ],
      required: true,
      visible: true,
      // checked: false,
    },
  ],
];

const filterLabels = {
  ID: "id",
  serial_number: "serial_number",
  vm_name: "vm_name",
  operator: "company",
  location: "location_name",
};

const sensorsFields = [
  "ID",
  "serial_number",
  "vm_name",
  "operator",
  "location",
];
let advanceFilterCD = null;
const Sensors = (props) => {
  const classes = styles();
  const [modulesList, setModulesList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [enableAgeVer, setEnableAgeVer] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [categoryList, setCategoryList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [versionList, setVersionList] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [workingStatusModal, setWorkingStatusModal] = useState(false);
  const [operatorStatusModal, setApprovalStatusModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [allocateModal, setAllocateModal] = useState(false);
  const [passcodeModal, setPasscodeModal] = useState(false);
  const [devicePasscode, setDevicePasscode] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [advanceFilterModal, setAdvanceFilterModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [filterable, setFilterable] = useState(sensorsFields);
  const [resetPasscodeLoader, setResetPasscodeLoader] = useState(false);
  const [advanceFilterParams, setAdvanceFilterParams] = useState({});
  const [typeSelection, setTypeSelection] = useState("");
  const [query, setQuery] = useState("");
  // const [deviceDataModal, setDeviceDataModal] = useState(false);
  // const [deviceData, setDeviceData] = useState({});
  const [hideEditModal, setHideEditModal] = useState(false);
  const [hideAllocateModal, setHideAllocateModal] = useState(false);
  const [clickedDevice, setClickedDevice] = useState({});
  const [rebootModal, setRebootModal] = useState(false);
  const [resetHardwareModal, setResetHardwareModal] = useState(false);
  const [setEdits, setEdit] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [verisonClick, setVerisonClick] = useState(false);
  const [versionData, setVersionData] = useState([]);
  const [showScannerFields, setShowScannerFields] = React.useState(false);
  const [showYotiFields, setShowYotiFields] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [mSeriesSelected, setMSeriesSelected] = useState(false);	
  const [mSeriesID, setMSeriesID] = useState(null);
  const [showSubmitLoader, setShowSubmitLoader] = useState(false);
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [showOperationsMenu, setShowOperationsMenu] = useState(null);
  const [crudLoader, setCrudLoader] = useState(false);
  const [bulkEditModal, setBulkEditModal] = useState(false);
  const [enableTaxes, setEnableTaxes] = useState(false);

  const qrRef = useRef(null);
  const enableTaxesPerVM = useSelector((state) => state.companySettingsReducer.enableTaxesPerVM);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  // Start Validation
  const SerialValidation = (field = "") => {
    var unicast = ["00:00:5E", "00:00-5E", "00-00-5E", "00-00:5E"];
    var multicast = ["01:00:5E", "01:00-5E", "01-00-5E", "01-00:5E"];
    if (
      field.match(/^(?:[A-Za-f0-9]{2}[:-]){5}(?:[A-Za-f0-9]{2})$/) ||
      unicast.indexOf(field.substring(0, 8).toUpperCase()) >= 0 ||
      multicast.indexOf(field.substring(0, 8).toUpperCase()) >= 0
    ) {
      return true;
    } else if (field.length === 12) {
      return true;
    }
  };

  const displayError = (errorMsg) => {
    setImportError(errorMsg);
    setIsInfo(!isInfo);
  }
  //End Validation
  const fields = [
    {
      key: "id",
      columnName: "ID",
      type: "text",
      visible: true,
    },
    {
      key: "serial_number",
      columnName: "Serial Number",
      label: "Serial Number",
      type: "text",
      required: true,
      visible: true,
      disabled: editModal === true,
      info: "Serial Number should be a valid MAC address.<br />Example MAC addresses are: 5A-83-74-BB-5F-2D, 5A:83:74:BB:5F:2D",
      validations: [
        {
          type: "custom",
          value: (fieldValue) => SerialValidation(fieldValue),
          message: "Not Valid Serial Number",
        },
      ],
      render: (value) => value || "---",
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      label: "VM Name",
      type: "text",
      required: true,
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "customer_name",
      form: false,
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      render: (value, field = {}) => {
        return value && (field.company || {}).customer_type === "Distributor"
          ? `${value} (${(field.company || {}).customer_type})`
          : value;
      },
    },
    {
      key: "location_details",
      label: "Location",
      columnName: "Location",
      type: "autocomplete",
      show: true,
      freeSolo: false,
      options: [
        ...locationList.map((x) => ({ label: x.location_name, value: x.id })),
      ],
      required: true,
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "oro_id",
      form: false,
      columnName: "Device Id",
      type: "text",
      required: true,
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "working_status",
      form: false,
      columnName: "Working Status",
      type: "text",
      required: true,
      visible: true,
      render: (value,val) => <div onClick={()=> {
        let ERROR_MSG = "Device deactivated by admin. SaaS overdue."
        let error =  "No errors available at this moment."
        if (val?.approval_status.toLowerCase() === "revoked" && val?.working_status =='outOfService'){
          error =  ERROR_MSG
        } else if (val?.machine_error && val?.working_status =='outOfService'){
          if ( val?.machine_error?.split("|") != ERROR_MSG){
          error = val?.machine_error?.split("|")
          }
        }
        displayError(error)}
        }>
        <WorkingStatus status={value} rec={val}/>
      </div>,
    },
    {
      key: "status",
      columnName: "Network Status",
      type: "text",
      required: false,
      visible: true,
      form: false,
      render: (value) => <DeviceConnection status={value} />,
    }, 
    {
      key: "approval_status",
      columnName: "Status",
      label: "Status",
      type: "text",
      visible: true,
      form: false
    },
    {
      key: "percentage_total_stock",
      columnName: "Percentage Total Stock",
      label: "Percentage total stock",
      type: "text",
      required: true,
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "device_type",
      label: "Type",
      columnName: "Device Type",
      type: "autocomplete",
      show: true,
      freeSolo: false,
      options: [
        ...categoryList.map((x) => ({ label: x.device_type, value: x.id })),
      ],
      required: true,
      visible: true,
    },
    {
      key: "payment_gateway",
      label: "Payment Gateway",
      columnName: "Payment Gateway",
      type: "text",
      render: (val) => val || "---",
      visible: true,
    },
    {
      key: "tax_percentage",
      label: "Tax (%)",
      columnName: "Tax (%)",
      type: "number",
      render: (value) => value ?? "0.0000",
      visible: enableTaxes,
    },
    [
      {
        key: "company",
        columnName: "Operator",
        label: "Operator",
        type: "autocomplete",
        show: true,
        freeSolo: false,
        required: false,
        options: [
          ...customerList.map((x) => ({
            label: `${x.business_name} (${x.customer_type})`,
            value: x.id,
          })),
        ],
        visible: true,
        render: (value) => {
          return (value || {}).business_name;
        },
      },
    ],
    [
      {
        key: "versions",
        label: "Version",
        type: "select",
        options: versionList.map((x) => ({ label: x.versionId, value: x.id })),
        visible: true,
      },
    ],
    {
      key: "version_id",
      columnName: "Version",
      type: "text",
      form: false,
      required: true,
      visible: true,
      disableSorting: true,
      render: (_, value) =>
        value?.versions?.main_app_versionid ? (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setVerisonClick(true);
              setVersionData(value?.versions);
            }}
          >
            {value?.versions?.main_app_versionid}
          </span>
        ) : (
          "---"
        ),
    },
    {
      key: "data",
      columnName: "Operations",
      type: "text",
      form: false,
      required: false,
      visible: true,
      disableSorting: true,
      render: (_, value) => (
        <div style={{ display: "flex" }}>
          <BootstrapTooltip title="Main">
            <IconButton
              size="small"
              className="m-1"
              variant="contained"
              color={"primary"}
              onClick={() =>
                history.push({
                  pathname: `/vending-machines/${value.id}`,
                  state: {
                    is_m_series: value.is_m_series,
                    modules: value.modules
                  },
                })
              }
            >
              <Kitchen />
            </IconButton>
          </BootstrapTooltip>

          <BootstrapTooltip title="Peer">
            <IconButton
              size="small"
              className="m-1"
              variant="contained"
              color={value.has_peer_device ? "primary" : "default"}
              onClick={
                value.has_peer_device
                  ? () =>
                      history.push(
                        `/vending-machines/peer-device/${value.serial_number}`
                      )
                  : ""
              }
            >
              <ChromeReaderModeIcon />
            </IconButton>
          </BootstrapTooltip>
        </div>
      )
    },
    {
      key: "first_connection",
      columnName: "First Connected",
      type: "text",
      required: true,
      form: false,
      visible: true,
      render: (value) => {
        return value ? dateFormatter(value, true) : "---";
      },
    },
    {
      key: "created_at",
      columnName: "Created At",
      type: "text",
      required: true,
      form: false,
      visible: true,
      render: (value) => {
        return value ? dateFormatter(value, true) : "---";
      },
    },
  ];

  const adfields = [
    [
      {
        key: "serial_number",
        columnName: "Serial Number",
        label: "Serial Number",
        type: "text",
        required: true,
        visible: true,
        disabled: editModal === true,
        tabindex: "1",
        info: "Serial Number should be a valid MAC address.<br />Example MAC addresses are: 5A-83-74-BB-5F-2D, 5A:83:74:BB:5F:2D",
        validations: [
          {
            type: "custom",
            value: (fieldValue) => SerialValidation(fieldValue),
            message: "Not Valid Serial Number",
          },
        ],
      },
      {
        key: "vm_name",
        columnName: "VM Name",
        label: "VM Name",
        type: "text",
        required: true,
        visible: true,
      },
    ],
    [
      {
        key: "location",
        label: "Location",
        columnName: "Location",
        type: "autocomplete",
        show: true,
        freeSolo: false,
        options: [
          ...locationList.map((x) => ({
            label: x.location_name,
            value: x.location_id,
          })),
        ],
        required: true,
        visible: true,
      },
    ],

    {
      key: "device_type",
      label: "Type",
      columnName: "Device Type",
      type: "autocomplete",
      show: true,
      freeSolo: false,
      options: [
        ...categoryList.map((x) => ({ label: x.device_type, value: x.id })),
      ],
      required: true,
      visible: true,
    },

    [
      {
        key: "company",
        columnName: "Operator",
        label: "Operator",
        type: "autocomplete",
        show: true,
        freeSolo: false,
        required: true,
        options: [
          ...customerList.map((x) => ({
            label: `${x.business_name} (${x.customer_type})`,
            value: x.id,
          })),
        ],
        visible: true,
        render: (value) => {
          return (value || {}).business_name;
        },
      },
    ],

    {
      key: "versions",
      label: "Version",
      columnName: "Version",
      type: "autocomplete",
      show: true,
      freeSolo: false,
      options: [
        ...versionList.map((x) => ({
          label: `${x.name} || ${x.versionId || ""}`,
          value: x.id,
        })),
      ],
      visible: true,
      form: editModal ? false : true,
    },
    {
      key: "age_verification_method",
      label: "Age Verification Method",
      visible: enableAgeVer,
      type: "select",
      required: true,
      options: [
        { label: "None", value: "disabled" },
        { label: "ID Scanner", value: "id_scanner" },
        { label: "Yoti", value: "yoti" },
      ],
      defaultValue: "disabled",
      unclosable: true
    },
    {
      key: "scanner_license",
      label: "ID Scanner License Key",
      type: "text",
      visible: !enableAgeVer ? false : showScannerFields,
      required: true,
    },
    [
      {
        key: "yoti_api_key",
        label: "Yoti Api Key",
        required: true,
        type: "text",
        visible: !enableAgeVer ? false : showYotiFields,
      },
      {
        key: "yoti_client_sdk_id",
        label: "Yoti Client Sdk ID",
        required: true,
        type: "text",
        visible: !enableAgeVer ? false : showYotiFields,
      },
    ],
    {
      key: "payment_gateway",
      label: "Payment Gateway",
      columnName: "Payment Gateway",
      type: "autocomplete",
      required: true,
      show: true,
      freeSolo: false,
      disableClearable: true,
      options: [
        { label: "Magtek (TSYS)", value: "Magtek (TSYS)" },
        { label: "Magtek (CHASE)", value: "Magtek (CHASE)" },
        { label: "NAYAX", value: "nayax" },
        { label: "PinDebit", value: "PinDebit" }
      ],
    },
    {
      key: "shoppingcart_limit",
      label: "Shopping Cart Limit",
      type: "number",
      isInteger: true,
      show: true,
      validations:[{
        type:'custom',
        value:(fieldValue)=>fieldValue>=1 && fieldValue<=5,
        message:"Write Number between 1 to 5"
    }],
      visible: true,
      required: true,
      min: 1,
      max: 5,
    },
    {
      key: "tax_percentage",
      columnName: "Tax (%)",
      label: "Tax (%)",
      type: "text",
      visible: enableTaxes,
      required: false,
      
      validations: [
        {
          type: "custom",
          value: (fieldValue) => enableTaxes  ? taxValidation(fieldValue) : true,
          message: "Enter tax between 0 to 100, only four digits are allowed after decimal",
        },
      ],
    }
    // {
    //   key: "no_dispense_device",
    //   type: 'checkbox',
    //   label: 'No Dispense',
    //   value: false,
    //   visible:true
    // },
  ];

  const   operatorFields =  [
    {
      key: "id",
      columnName: "ID",
      type: "text",
      visible: true,
    },
    {
      key: "serial_number",
      columnName: "Serial Number",
      label: "Serial Number",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      label: "VM Name",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "company",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      render: (value) => {
        return (value || {}).business_name || "---";
      },
    },
    {
      key: editModal || addModal ? "location" : "location_details",
      label: "Location",
      columnName: "Location",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "oro_id",
      columnName: "Device Id",
      type: "text",
      visible: true,
      render: (value) => value || "---",
      visible:
        props.currentUser.type === "OP" || props.currentUser.type === "FO" || props.currentUser.type === "DU"
          ? false
          : true,
    },
    {
      key: "working_status",
      form: false,
      columnName: "Working Status",
      type: "text",
      visible: true,
      render: (value,val) => <div onClick={()=> {
        if(value?.toLowerCase() === 'inservice') return;
        // console.log(val.approval_status,val.working_status);
        
         const error= val?.approval_status.toLowerCase() === "revoked" ? "Device deactivated by admin. SaaS overdue.":val?.machine_error && val?.machine_error?.split("|") ? val?.machine_error?.split("|") : 
        "No errors available at this moment."
        displayError(error)}
        }>
        <WorkingStatus status={value} rec={val}/>
      </div>,
    },
    {
      key: "status",
      columnName: "Network Status",
      type: "text",
      visible: true,
      render: (value) => <DeviceConnection status={value} />,
    },
    {
      key: "approval_status",
      columnName: "Status",
      label: "Status",
      type: "text",
      visible: true,
      form: false
    },

    {
      key: "chiller_temperature",
      columnName: "Chiller Temperature",
      label: "Chiller Temperature",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "device_temperature",
      columnName: "Temperature",
      label: "Temperature",
      type: "text",
      visible: true,
      disableSorting: true,
      render: (value) => value || "---",
    },
    {
      key: "device_type",
      label: "Device Type",
      columnName: "Device Type",
      type: "text",
      render: (val) => val || "---",
      visible: true,
    },
    {
      key: "payment_gateway",
      label: "Payment Gateway",
      columnName: "Payment Gateway",
      type: "text",
      render: (val) => val || "---",
      visible: true,
    },
    {
      key: "controller",
      label: "Controller",
      columnName: "Controller",
      type: "select",
      form:true,
      options: [
        { label: "C31", value: "C31" },
        { label: "InHand", value: "InHand" },
      ],
      required: true,
      visible: true,
      render: (value) => value || "---",
      unclosable: true
    },
    {
      key: "tax_percentage",
      label: "Tax (%)",
      columnName: "Tax (%)",
      type: "text",
      render: (value) => taxFormatter(value) ?? "0.0000",
      visible: enableTaxes,
    },
    {	
      key: "module_number",	
      label: "Modules Count",	
      columnName: "Modules Count",	
      type: "text",	
      visible: true,
      render: (val) => val || "---",
    },
    {
      key: "version_id",
      columnName: "Version",
      type: "text",
      form: false,
      required: true,
      visible: true,
      disableSorting: true,
      render: (_, value) =>
        value?.versions?.main_app_versionid ? (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setVerisonClick(true);
              setVersionData(value?.versions);
            }}
          >
            {value?.versions?.main_app_versionid}
          </span>
        ) : (
          "---"
        ),
    },
    [
      {
        key: "versions",
        label: "Version",
        columnName: "Version",
        type: "autocomplete",
        show: true,
        freeSolo: false,
        options: [
          ...versionList.map((x) => ({
            label: `${x.name} || ${x.versionId || ""}`,
            value: x.id,
          })),
        ],
        visible: true,
        form: editModal ? false : true,
      },
    ],
    {
      key: "data",
      columnName: "Operations",
      type: "text",
      form: false,
      required: false,
      visible: true,
      disableSorting: true,
      render: (_, value) => (
        <>
          <BootstrapTooltip title="Cabinet">
            <IconButton
              size="small"
              variant="contained"
              color="primary"
              onClick={() => history.push({
                pathname: `/vending-machines/${value.id}`,
                state: {
                  is_m_series: value.is_m_series,
                  modules: value.modules
                },
              })}
            >
              <Kitchen />
            </IconButton>
          </BootstrapTooltip>

          <BootstrapTooltip title="Peer">
            <IconButton
              variant="contained"
              disabled={!value.has_peer_device}
              color="primary"
              onClick={() =>
                history.push(
                  `/vending-machines/peer-device/${value.serial_number}`
                )
              }
            >
              <ChromeReaderModeIcon />
            </IconButton>
          </BootstrapTooltip>
        </>
      ),
    },
    {
      key: "age_verification_method",
      label: "Age Verification Method",
      columnName: "Age Verification Method",
      visible: enableAgeVer,
      type: "select",
      required: true,
      options: [
        { label: "None", value: "disabled" },
        { label: "ID Scanner", value: "id_scanner" },
        { label: "Yoti", value: "yoti" },
      ],
      defaultValue: "disabled",
      unclosable: true
    },
    {
      key: "scanner_license",
      label: "ID Scanner License Key",
      type: "text",
      visible: !enableAgeVer ? false : showScannerFields,
      required: true,
    },
    [
      {
        key: "yoti_api_key",
        label: "Yoti Api Key",
        required: true,
        type: "text",
        visible: !enableAgeVer ? false : showYotiFields,
      },
      {
        key: "yoti_client_sdk_id",
        label: "Yoti Client Sdk ID",
        required: true,
        type: "text",
        visible: !enableAgeVer ? false : showYotiFields,
      },
    ],
    // {
    //   key: "no_dispense_device",
    //   type: 'checkbox',
    //   label: 'No Dispense',
    //   value: false,
    //   visible:true
    // },
  ];

  const daFields = [
    {
      key: "serial_number",
      columnName: "Serial Number",
      label: "Serial Number",
      type: "text",
      required: true,
      visible: true,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => SerialValidation(fieldValue),
          message: "Not Valid Serial Number",
        },
      ],
      disabled: editModal === true,
      info: "Serial Number should be a valid MAC address.<br />Example MAC addresses are: 5A-83-74-BB-5F-2D, 5A:83:74:BB:5F:2D",
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      label: "VM Name",
      type: "text",
      required: true,
      visible: true,
    },
    {
      key: "company",
      columnName: "Operator",
      label: "Operator",
      type: "autocomplete",
      show: true,
      freeSolo: false,
      required: true,
      options: [
        ...customerList.map((x) => ({
          label: `${x.business_name} (${x.customer_type})`,
          value: x.id,
        })),
      ],
      visible: true,
      render: (value) => {
        return (value || {}).business_name;
      },
    },
    {
      key: "area_name",
      columnName: "Area",
      show: true,
      form: false,
      freeSolo: false,
      required: true,
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: editModal || addModal ? "location" : "location_details",
      label: "Location",
      columnName: "Location",
      type: "autocomplete",
      show: true,
      freeSolo: false,
      options: [
        ...locationList.map((x) => ({
          label: x.location_name,
          value: x.location_id,
        })),
      ],
      required: true,
      visible: true,
    },
    {
      key: "oro_id",
      form: false,
      columnName: "Device Id",
      type: "text",
      required: true,
      visible:
        props.currentUser.type === "OP" || props.currentUser.type === "FO" || props.currentUser.type === "DU"
          ? false
          : true,
    },
    {
      key: "working_status",
      form: false,
      columnName: "Working Status",
      type: "text",
      required: true,
      visible: true,
      render: (value) => <WorkingStatus status={value} />,
    },
    {
      key: "status",
      columnName: "Network Status",
      type: "text",
      required: false,
      visible: true,
      form: false,
      render: (value) => <DeviceConnection status={value} />,
    },

    {
      key: "chiller_temperature",
      columnName: "Chiller Temperature",
      label: "Chiller Temperature",
      type: "text",
      required: true,
      form: false,
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "device_temperature",
      columnName: "Temperature",
      label: "Temperature",
      type: "text",
      required: true,
      form: false,
      visible: true,
      disableSorting: true,
      render: (value) => value || "---",
    },

    {
      key: "device_type_category_name",
      form: false,
      columnName: "Device Type Category",
      show: true,
      visible: true,
      render: (val) => val || "---",
    },
    {
      key: "device_type",
      label: "Type",
      columnName: "Device Type",
      type: "autocomplete",
      show: true,
      freeSolo: false,
      options: [
        ...categoryList.map((x) => ({ label: x.device_type, value: x.id })),
      ],
      required: true,
      visible: true,
    },
    {
      key: "controller",
      label: "Controller",
      columnName: "Controller",
      type: "select",
      form:true,
      options: [
        { label: "C31", value: "C31" },
        { label: "InHand", value: "InHand" },
      ],
      required: true,
      visible: true,
      render: (value) => value || "---",
      unclosable: true
    },
    {	
      key: "module_number",	
      label: "Modules Count",	
      columnName: "Modules Count",	
      type: "number",	
      required: mSeriesSelected,	
      visible: mSeriesSelected,	
      isInteger: true,	
      max: 10,
      min: 1
    },
    {
      key: "version_id",
      columnName: "Version",
      type: "text",
      form: false,
      required: true,
      visible: true,
      render: (_, value) =>
        value?.versions?.main_app_version?.versionId ? (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setVerisonClick(true);
              setVersionData(value?.versions);
            }}
          >
            {value?.versions?.main_app_version?.versionId}
          </span>
        ) : (
          "---"
        ),
    },
    [
      {
        key: "versions",
        label: "Version",
        columnName: "Version",
        type: "autocomplete",
        show: true,
        freeSolo: false,
        options: [
          ...versionList.map((x) => ({
            label: `${x.name} || ${x.versionId || ""}`,
            value: x.id,
          })),
        ],
        visible: true,
        form: editModal ? false : true,
      },
    ],
    {
      key: "data",
      columnName: "Operations",
      type: "text",
      form: false,
      required: false,
      visible: true,
      render: (_, value) => (
        <>
          <BootstrapTooltip title="Main">
            <IconButton
              size="small"
              variant="contained"
              color="primary"
              onClick={() => history.push({
                pathname: `/vending-machines/${value.id}`,
                state: {
                  is_m_series: value.is_m_series,
                  modules: value.modules
                },
              })}
            >
              <Kitchen />
            </IconButton>
          </BootstrapTooltip>

          <BootstrapTooltip title="Peer">
            <IconButton
              variant="contained"
              disabled={!value.has_peer_device}
              color="primary"
              onClick={() =>
                history.push(
                  `/vending-machines/peer-device/${value.serial_number}`
                )
              }
            >
              <ChromeReaderModeIcon />
            </IconButton>
          </BootstrapTooltip>
        </>
      ),
    },
    {
      key: "age_verification_method",
      label: "Age Verification Method",
      columnName: "Age Verification Method",
      visible: enableAgeVer,
      type: "select",
      required: true,
      options: [
        { label: "None", value: "disabled" },
        { label: "ID Scanner", value: "id_scanner" },
        { label: "Yoti", value: "yoti" },
      ],
      defaultValue: "disabled",
      unclosable: true
    },
    {
      key: "scanner_license",
      label: "ID Scanner License Key",
      type: "text",
      visible: !enableAgeVer ? false : showScannerFields,
      required: true,
    },
    [
      {
        key: "yoti_api_key",
        label: "Yoti Api Key",
        required: true,
        type: "text",
        visible: !enableAgeVer ? false : showYotiFields,
      },
      {
        key: "yoti_client_sdk_id",
        label: "Yoti Client Sdk ID",
        required: true,
        type: "text",
        visible: !enableAgeVer ? false : showYotiFields,
      },
    ],
    {
      key: "payment_gateway",
      label: "Payment Gateway",
      columnName: "Payment Gateway",
      type: "autocomplete",
      required: true,
      show: true,
      freeSolo: false,
      disableClearable: true,
      options: [
        { label: "Magtek (TSYS)", value: "Magtek (TSYS)" },
        { label: "Magtek (CHASE)", value: "Magtek (CHASE)" },
        { label: "NAYAX", value: "nayax" },
        { label: "PinDebit", value: "PinDebit" }
      ],
    },
    {
      key: "shoppingcart_limit",
      label: "Shopping Cart Limit",
      type: "number",
      isInteger: true,
      show: true,
      validations:[{
        type:'custom',
        value:(fieldValue)=>fieldValue>=1 && fieldValue<=5,
        message:"Write Number between 1 to 5"
    }],
      visible: true,
      required: true,
      min: 1,
      max: 5,
    },
    {
      key: "tax_percentage",
      columnName: "Tax (%)",
      label: "Tax (%)",
      type: "text",
      visible: enableTaxes,
      required: false,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => enableTaxes  ? taxValidation(fieldValue) : true,
          message: "Enter tax between 0 to 100, only four digits are allowed after decimal",
        },
      ],
    }
    // {
    //   key: "no_dispense_device",
    //   type: 'checkbox',
    //   label: 'No Dispense',
    //   value: false,
    //   visible:true
    // },
  ];

  const taxValidation = (value) => {
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 100 &&
      ((''+value)?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 4 : true)
    ) {
      return true;
    }
  };

  const editdaFields = [
    {
      key: "serial_number",
      columnName: "Serial Number",
      label: "Serial Number",
      type: "text",
      required: true,
      visible: true,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => SerialValidation(fieldValue),
          message: "Not Valid Serial Number",
        },
      ],
      disabled: editModal === true,
      info: "Serial Number should be a valid MAC address.<br />Example MAC addresses are: 5A-83-74-BB-5F-2D, 5A:83:74:BB:5F:2D",
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      label: "VM Name",
      type: "text",
      required: true,
      visible: true,
    },
    {
      key: "company",
      columnName: "Operator",
      label: "Operator",
      type: "autocomplete",
      show: true,
      freeSolo: false,
      required: true,
      options: [
        ...customerList.map((x) => ({
          label: `${x.business_name} (${x.customer_type})`,
          value: x.id,
        })),
      ],
      visible: true,
      render: (value) => {
        return (value || {}).business_name;
      },
    },
    {
      key: "area_name",
      columnName: "Area",
      show: true,
      form: false,
      freeSolo: false,
      required: true,
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: editModal || addModal ? "location" : "location_details",
      label: "Location",
      columnName: "Location",
      type: "autocomplete",
      show: true,
      freeSolo: false,
      options: [
        ...locationList.map((x) => ({
          label: x.location_name,
          value: x.location_id,
        })),
      ],
      required: true,
      visible: true,
    },
    {
      key: "oro_id",
      form: false,
      columnName: "Device Id",
      type: "text",
      required: true,
      visible:
        props.currentUser.type === "OP" || props.currentUser.type === "FO" || props.currentUser.type === "DU"
          ? false
          : true,
    },
    {
      key: "working_status",
      form: false,
      columnName: "Working Status",
      type: "text",
      required: true,
      visible: true,
      render: (value) => <WorkingStatus status={value} />,
    },
    {
      key: "status",
      columnName: "Network Status",
      type: "text",
      required: false,
      visible: true,
      form: false,
      render: (value) => <DeviceConnection status={value} />,
    },

    {
      key: "chiller_temperature",
      columnName: "Chiller Temperature",
      label: "Chiller Temperature",
      type: "text",
      required: true,
      form: false,
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "device_temperature",
      columnName: "Temperature",
      label: "Temperature",
      type: "text",
      required: true,
      form: false,
      visible: true,
      disableSorting: true,
      render: (value) => value || "---",
    },

    {
      key: "device_type_category_name",
      form: false,
      columnName: "Device Type Category",
      show: true,
      visible: true,
      render: (val) => val || "---",
    },
    {
      key: "device_type",
      label: "Type",
      columnName: "Device Type",
      type: "autocomplete",
      show: true,
      freeSolo: false,
      options: mSeriesSelected ? [
        ...categoryList.map((x) => ({ label: x.device_type, value: x.id })),
      ] : [
        ...categoryList.filter(obj => obj.device_type != "M Series").map((x) => ({ label: x.device_type, value: x.id })),
      ],
      required: true,
      visible: true,
      disabled: mSeriesSelected	
    },
    {
      key: "controller",
      label: "Controller",
      columnName: "Controller",
      type: "select",
      form:true,
      options: [
        { label: "C31", value: "C31" },
        { label: "InHand", value: "InHand" },
      ],
      required: true,
      visible: true,
      render: (value) => value || "---",
      unclosable: true
    },
    {	
      key: "module_number",	
      label: "Modules Count",	
      columnName: "Modules Count",	
      type: "number",	
      required: mSeriesSelected,	
      visible: mSeriesSelected,	
      isInteger: true,	
      max: 10,
      min: 1,
      disabled: mSeriesSelected
    },
    {
      key: "version_id",
      columnName: "Version",
      type: "text",
      form: false,
      required: true,
      visible: true,
    },
    [
      {
        key: "versions",
        label: "Version",
        columnName: "Version",
        type: "autocomplete",
        show: true,
        freeSolo: false,
        options: [
          ...versionList.map((x) => ({
            label: `${x.name} || ${x.versionId || ""}`,
            value: x.id,
          })),
        ],
        visible: true,
        form: editModal ? false : true,
      },
    ],
    {
      key: "data",
      columnName: "Operations",
      type: "text",
      form: false,
      required: false,
      visible: true,
      render: (_, value) => (
        <>
          <BootstrapTooltip title="Main">
            <IconButton
              size="small"
              variant="contained"
              color="primary"
              onClick={() => history.push(`/vending-machines/${value.id}`)}
            >
              <Kitchen />
            </IconButton>
          </BootstrapTooltip>

          <BootstrapTooltip title="Peer">
            <IconButton
              variant="contained"
              disabled={!value.has_peer_device}
              color="primary"
              onClick={() =>
                history.push(
                  `/vending-machines/peer-device/${value.serial_number}`
                )
              }
            >
              <ChromeReaderModeIcon />
            </IconButton>
          </BootstrapTooltip>
        </>
      ),
    },
    {
      key: "age_verification_method",
      label: "Age Verification Method",
      visible: enableAgeVer,
      type: "select",
      required: true,
      options: [
        { label: "None", value: "disabled" },
        { label: "ID Scanner", value: "id_scanner" },
        { label: "Yoti", value: "yoti" },
      ],
      defaultValue: "disabled",
      unclosable: true
    },
    {
      key: "scanner_license",
      label: "ID Scanner License Key",
      type: "text",
      visible: !enableAgeVer ? false : showScannerFields,
      required: true,
    },
    [
      {
        key: "yoti_api_key",
        label: "Yoti Api Key",
        required: true,
        type: "text",
        visible: !enableAgeVer ? false : showYotiFields,
      },
      {
        key: "yoti_client_sdk_id",
        label: "Yoti Client Sdk ID",
        required: true,
        type: "text",
        visible: !enableAgeVer ? false : showYotiFields,
      },
    ],
    {
      key: "payment_gateway",
      label: "Payment Gateway",
      columnName: "Payment Gateway",
      type: "autocomplete",
      required: true,
      show: true,
      freeSolo: false,
      disableClearable: true,
      options: [
        { label: "Magtek (TSYS)", value: "Magtek (TSYS)" },
        { label: "Magtek (CHASE)", value: "Magtek (CHASE)" },
        { label: "NAYAX", value: "nayax" },
        { label: "PinDebit", value: "PinDebit" }
      ],
    },
    {
      key: "shoppingcart_limit",
      label: "Shopping Cart Limit",
      type: "number",
      isInteger: true,
      show: true,
      validations:[{
        type:'custom',
        value:(fieldValue)=>fieldValue>=1 && fieldValue<=5,
        message:"Write Number between 1 to 5"
    }],
      visible: true,
      required: true,
      min: 1,
      max: 5,
    },
    {
      key: "tax_percentage",
      columnName: "Tax (%)",
      label: "Tax (%)",
      type: "text",
      visible: enableTaxes,
      required: false,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => enableTaxes  ? taxValidation(fieldValue) : true,
          message: "Enter tax between 0 to 100, only four digits are allowed after decimal",
        },
      ],
    }
    // {
    //   key: "no_dispense_device",
    //   type: 'checkbox',
    //   label: 'No Dispense',
    //   value: false,
    //   visible:true
    // },
  ];

  const bulkEditFields = [
    {
      key: "tax_percentage",
      columnName: "Tax (%)",
      label: "Tax (%)",
      type: "text",
      visible: enableTaxes,
      required: false,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => enableTaxes  ? taxValidation(fieldValue) : true,
          message: "Enter tax between 0 to 100, only four digits are allowed after decimal",
        },
      ],
    }
  ];

  const allocateFields = [
    {
      key: "company",
      columnName: "Operators",
      label: "Operators",
      type: "autocomplete",
      show: true,
      freeSolo: false,
      required: props.currentUser.type === "DA",
      options: [
        ...customerList.map((x) => ({
          label: `${x.business_name} (${x.customer_type})`,
          value: x.id,
        })),
      ],
      visible: true,
      render: (value) => {
        return (value || {}).business_name;
      },
    },
  ];

  const passcodeFields = [
    {
      key: "passcode",
      disabled: true,
      copyable: true,
      value: devicePasscode,
      type: "text",
      required: true,
      visible: true,
    },
  ];

  const advanceFilterFields = [
    {
      key: "serial_number",
      label: "Serial number",
      type: "text",
      visible: true,
    },
    { key: "oro_id", label: "Device Id", type: "text", visible: true },
    [
      {
        key: "device_type",
        label: "Type",
        freeSolo: true,
        show: true,
        type: "autocomplete",
        options: [
          ...categoryList.map((x) => ({
            label: x.device_type,
            value: x.device_type,
          })),
        ],
        visible: true,
      },
      {
        key: "company",
        freeSolo: true,
        label: "Operator",
        type: "autocomplete",
        show: true,
        options: [
          ...customerList.map((x) => ({
            label: x.business_name,
            value: x.business_name,
          })),
        ],
        visible: true,
      },
    ],
    [
      {
        key: "factories",
        freeSolo: true,
        label: "Factory",
        options: [
          ...factoryList.map((x) => ({
            label: x.factoryId,
            value: x.factoryId,
          })),
        ],
        type: "autocomplete",
        show: true,
        visible: true,
      },
      {
        key: "groups",
        freeSolo: true,
        label: "Group",
        options: [...groupList.map((x) => ({ label: x.name, value: x.name }))],
        type: "autocomplete",
        show: true,
        visible: true,
      },
    ],
    {
      key: "scope",
      columnName: "Scope",
      label: "Scope",
      type: "select",
      visible: true,
      options: [
        { label: "Equals", value: "equals" },
        { label: "Contains", value: "contains" },
      ],
      value: "equals",
    },
  ];

  const setup = () => {
    setLoader(true);
    setModulesList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    const newData = data.results.map((val) => ({
      ...val,
      status: val.status ? "Connected" : "Not Connected",
      // device_type: val.device_type ? val.device_type : "",
      version_id: (val.versions || {}).versionId,
      factoryId: (val.factories || {}).factoryId,
      customer_name: (val.company || {}).business_name
        ? val.company.business_name
        : "",
      group_name: (val.groups || {}).name ? val.groups.name : "",
    }));
    setModulesList(newData);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const rebootDevice = async () => {
    setShowSubmitLoader(true);
    let fd = new FormData();
    fd.append("action", "reboot");
    fd.append("device_id", clickedDevice.oro_id);
    try {
      const { data } = await window.axiosIns.post(
        "manage_device/reboot_device",
        fd
      );
      enqueueSnackbar("Device Reboot Successful.");
      setClickedDevice({});
      setRebootModal(false);
      getModules();
    } catch (err) {
      return handleServerErrors(err, enqueueSnackbar);
    } finally {
      setShowSubmitLoader(false);
    }
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      if (searchFilter["is_virtual"] !== undefined) {
        if (query.toLowerCase() === "yes") {
          searchFilter["is_virtual"] = "true";
        } else if (query.toLowerCase() === "no") {
          searchFilter["is_virtual"] = "false";
        }
      }

      setup();
      setPage(0);
      ModuleService.search({
        ...searchFilter,
        limit: rowsPerPage,
        ordering: ordering,
      })
        .then(({ data }) => {
          handleRes(data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
      if (searchFilter["is_virtual"] !== undefined) {
        if (value.toLowerCase() === "yes") {
          searchFilter["is_virtual"] = "true";
        } else if (value.toLowerCase() === "no") {
          searchFilter["is_virtual"] = "false";
        }
      }
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    ModuleService.search({
      ...searchFilter,
      limit: rowsPerPage,
      ordering: ordering,
    })
      .then(({ data }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const getModules = (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
    };
    setup();
    ModuleService.modules(params)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get devices. Try again."
          );
        }
      });
  };

  const getCategory = () => {
    CustomerService.getCategory({ all: true, ordering: "category_name" })
      .then(({ data = {} }) => {
        const mSeriesObject = data.results?.find(obj => obj.device_type === "M Series");	
        if (mSeriesObject) {	
          setMSeriesID(mSeriesObject.id);	
        }
        setCategoryList(data.results);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get categories. Try again."
          );
        }
      });
  };

  const getCustomers = () => {
    CustomerService.customers({ all: true, ordering: "business_name", lite: true })
      .then(({ data = {} }) => {
        setCustomerList(data.results || []);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get Operator. Try again."
          );
        }
      });
  };

  const getVersions = () => {
    VersionService.list({ all: true, ordering: "name", lite: true }).then(
      ({ data = {} }) => {
        setVersionList(data.results);
      }
    );
  };

  const getLocation = async (order, max) => {
    const params = {
      // limit: max ? max : rowsPerPage,
      // ordering: order ? order : ordering,
      // page: page + 1,
      all: true,
      sub_operator: true,
      ordering: "location_name",
      lite: true
    };
    try {
      // setup();
      const { data } = await window.axiosIns("locations/locations", { params });
      setLocationList(data.results || []);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getModules();
    getCustomers();
    getVersions();
    getCategory();
    getLocation();
  }, [triggerRefresh]);

  useEffect(() => {
    setEnableAgeVer(JSON.parse(localStorage.getItem("scanner_state")));
  }, [JSON.parse(localStorage.getItem("scanner_state"))]);

  useEffect(() => {
    setEnableTaxes(enableTaxesPerVM);
  }, [enableTaxesPerVM]);

  const handleWorkingStatus = (values, modulesSelected) => {
    let fd = new FormData();
    fd.append("working_status", values.working_status);
    let devices = [];
    modulesSelected.forEach((val) => {
      devices.push(val.oro_id);
    });
    fd.append("devices", devices);
    WorkingStatusService.UpdateStatus(fd);
    enqueueSnackbar("Device status updated successfully");
    setWorkingStatusModal(false);
    setTimeout(() => {
      setModulesSelected([]);
      getModules();
    }, 2000);
  };

  const updateApprovalStatus = ({ approval_status }) => {
    if(!approval_status) {
        enqueueSnackbar("Please select a status.");
        return;
    }
    setShowSubmitLoader(true);
    let deviceIds = modulesSelected.map((value) => value?.id);
    let data = {
      devices: deviceIds,
      approval_status: approval_status,
    };
    ModuleService.updateStatus(data)
      .then(() => {
        enqueueSnackbar("Vending machine (s) status updated successfully.");
        setApprovalStatusModal(false);
        setTimeout(() => {
          setModulesSelected([]);
          getModules();
        }, 2000);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not update status. Try again."
          );
        }
      }).finally(() => {
        setShowSubmitLoader(false);
      });
  };

  const handleAdd = ({
    serial_number,
    company = null,
    versions = null,
    groups = null,
    device_type = null,
    date_mfg,
    factories = null,
    vm_name,
    location,
    age_verification_method = "disabled",
    approval_status="Approval Pending",
    scanner_license = "",
    yoti_api_key = "",
    yoti_client_sdk_id = "",
    module_number = null,
    shoppingcart_limit,
    payment_gateway,
    tax_percentage,
    controller
  }) => {

    if (!tax_percentage || tax_percentage > 100 || tax_percentage < 0) {
      enqueueSnackbar("Please enter tax between 0 to 100.");
      return;
    }

    setShowSubmitLoader(true);
    setCrudLoader(true);
    if (age_verification_method === "disabled") {
      scanner_license = "";
      yoti_api_key = "";
      yoti_client_sdk_id = "";
    } else if (age_verification_method === "id_scanner") {
      yoti_api_key = "";
      yoti_client_sdk_id = "";
    } else if (age_verification_method === "yoti") {
      scanner_license = "";
    }
    ModuleService.addModule({
      serial_number,
      company,
      versions,
      vm_name,
      groups,
      device_type,
      date_mfg,
      factories,
      approval_status,
      location,
      age_verification_method,
      scanner_license,
      yoti_api_key,
      yoti_client_sdk_id,
      shoppingcart_limit,
      payment_gateway,
      module_number: mSeriesSelected ? Number(module_number) : null,
      tax_percentage: tax_percentage ? Number(tax_percentage) : 0,
      controller
    })
      .then(() => {
        setCrudLoader(false);
        setAddModal(false);
        enqueueSnackbar("Device created successfully.");
        setShowScannerFields(false);
        setShowYotiFields(false);
      })
      .catch((err) => {
        setCrudLoader(false);
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add device. Try again."
          );
        }
      })
      .then(() => {
        setShowSubmitLoader(false);
        getModules();
        setModulesSelected([]);
      });
  };
  const handleAllocate = ({
    serial_number,
    company = null,
    versions = null,
    groups = null,
    device_type = null,
    date_mfg,
    factories = null,
  }) => {
    setShowSubmitLoader(true);
    const moduleId = (modulesSelected[0] || {}).id;
    ModuleService.editModule(moduleId, {
      company:
        typeof company === "object" &&
        company !== null &&
        company.hasOwnProperty("id")
          ? company.id
          : company,
      oro_id: modulesSelected[0]?.oro_id,
      serial_number,
      versions,
      groups,
      // device_type,
      date_mfg,
      factories,
    })
      .then(() => {
        setAllocateModal(false);
        enqueueSnackbar("Device allocated successfully.");
      })
      .catch((err) => {
        setAllocateModal(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not allocate device. Try again."
          );
        }
      })
      .then(() => {
        setShowSubmitLoader(false);
        setModulesList([]);
        setModulesSelected([]);
        getModules();
      });
  };

  const handleEdit = ({
    serial_number,
    company = null,
    versions = null,
    groups = null,
    device_type = null,
    date_mfg,
    factories = null,
    vm_name,
    location,
    age_verification_method = "disabled",
    scanner_license = "",
    yoti_api_key = "",
    yoti_client_sdk_id = "",
    module_number = null,
    shoppingcart_limit,
    payment_gateway,
    tax_percentage,
    controller
  }) => {

    if (!tax_percentage || tax_percentage > 100 || tax_percentage < 0) {
      enqueueSnackbar("Please enter tax between 0 to 100.");
      return;
    }

    setShowSubmitLoader(true);
    setCrudLoader(true);
    const moduleId = (modulesSelected[0] || {}).id;
    const oro_id = (modulesSelected[0] || {}).oro_id;
    setEdit(true);
    if (enableAgeVer) {
      if (age_verification_method === "disabled") {
        scanner_license = "";
        yoti_api_key = "";
        yoti_client_sdk_id = "";
      } else if (age_verification_method === "id_scanner") {
        yoti_api_key = "";
        yoti_client_sdk_id = "";
      } else if (age_verification_method === "yoti") {
        scanner_license = "";
      }
    } else {
      scanner_license = "";
      yoti_api_key = "";
      yoti_client_sdk_id = "";
      age_verification_method = "disabled";
    }
    ModuleService.editModule(moduleId, {
      company:
        typeof company === "object" &&
        company !== null &&
        company.hasOwnProperty("id")
          ? company.id
          : company,
      serial_number,
      versions,
      groups,
      device_type,
      date_mfg,
      factories,
      vm_name,
      location,
      oro_id,
      shoppingcart_limit,
      payment_gateway,
      ...(enableAgeVer && {
        age_verification_method,
        scanner_license,
        yoti_api_key,
        yoti_client_sdk_id,
      }),
      module_number: mSeriesSelected ? Number(module_number) : null,
      tax_percentage: tax_percentage ? Number(tax_percentage) : 0,
      controller
    })
      .then(() => {
        setCrudLoader(false);
        setEditModal(false);
        enqueueSnackbar("Device edited successfully.");
        setShowScannerFields(false);
        setShowYotiFields(false);
      })
      .catch((err) => {
        setCrudLoader(false);
        setEditModal(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit device. Try again."
          );
        }
      })
      .then(() => {
        setShowSubmitLoader(false);
        setModulesList([]);
        setModulesSelected([]);
        getModules();
      });
  };

  const handleBulkEdit = ({
    tax_percentage
  }) => {

    if (!tax_percentage || tax_percentage > 100 || tax_percentage < 0) {
      enqueueSnackbar("Please enter tax between 0 to 100.");
      return;
    }

    setShowSubmitLoader(true);
    setCrudLoader(true);
    let deviceIds = modulesSelected.map((value) => value?.id);
    ModuleService.bulkEdit({
      devices: deviceIds,
      tax_percentage: tax_percentage ? Number(tax_percentage) : 0,
    })
      .then(() => {
        setBulkEditModal(false);
        enqueueSnackbar("Taxes updated successfully.");
        setModulesList([]);
        setModulesSelected([]);
        getModules();
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not update taxes. Please try again."
          );
        }
      })
      .then(() => {
        setCrudLoader(false);
        setShowSubmitLoader(false);
      });
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get devices. Try again."
          );
        }
      });
  };

  const handleDelete = () => {
    setShowSubmitLoader(true);
    modulesSelected.forEach((val, index) => {
      ModuleService.deleteModule(val.id)
        .then(() => {
          setDeleteModal(false);
          if (modulesSelected.length === index + 1) {
            setShowSubmitLoader(false);
            enqueueSnackbar("Device(s) deleted successfully.");
            if (modulesList.length - modulesSelected.length === 0 && page > 0) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getModules();
            }
          }
        })
        .catch((err) => {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not delete device. Try again."
          );
        });
    });
  };

  const applyAdvancedSearch = (values = {}) => {
    const filteredValues = _.pickBy(values, _.identity);
    setAdvanceFilterParams(filteredValues);
    setLoader(true);
    setAdvanceFilterModal(false);
    ModuleService.advancedSearch(filteredValues)
      .then(({ data = {} }) => {
        const newData = data.results.map((val) => ({
          ...val,
          status: val.status ? "Connected" : "Not Connected",
          version_id: (val.versions || {}).versionId,
          // device_type: val.device_type ? val.device_type : "",
          factoryId: (val.factories || {}).factoryId,
          customer_name: (val.company || {}).business_name
            ? val.company.business_name
            : "",
          group_name: (val.groups || {}).name ? val.groups.name : "",
        }));
        setDataCount(data.count);
        setModulesList(newData);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        handleServerErrors(error, enqueueSnackbar, "Could not fetch devices.");
      })
      .then(() => {
        advanceFilterCD.setState({ fieldValues: {} });
        setAdvanceFilterParams({});
      });
  };

  const clearAdvanceFilter = () => {
    setAdvanceFilterParams({});
    advanceFilterCD.setState({ fieldValues: {} });
  };

  const resetPasscode = () => {
    setResetPasscodeLoader(true);
    ModuleService.resetPasscode(modulesSelected[0].id)
      .then((response) => {
        if (response.status === "success") {
          fetchDevicePasscode();
        }
      })
      .catch((error) => {
        setResetPasscodeLoader(false);
        handleServerErrors(
          error,
          enqueueSnackbar,
          "Could not reset device passcode."
        );
      });
  };

  const fetchDevicePasscode = () => {
    ModuleService.fetchPasscode(modulesSelected[0].id)
      .then((response) => {
        if (response.status === "success") {
          setDevicePasscode(response.data.pass_code);
        }
        setResetPasscodeLoader(false);
      })
      .catch((error) => {
        setResetPasscodeLoader(false);
        handleServerErrors(
          error,
          enqueueSnackbar,
          "Could not fetch device passcode."
        );
      });
  };

  const vmExport = () => {
    let params = {
      ...searchQuery,
      state: "all",
      tz: getTimeZoneDifference(),
    };
    window
      .axiosIns("/device/export", {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
        params: params,
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `VM-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const handleTypeSelection = (field, value) => {
    if (field.key === "device_type" && value === mSeriesID) {	
      setMSeriesSelected(true);	
    } else if (field.key === "device_type" && value !== mSeriesID) {	
      setMSeriesSelected(false);	
    }
    if (field.key === "") {
      setTypeSelection(value);
    }
  };

  const handleQrDownload = useCallback(() => {
    const qr = qrRef.current.innerHTML;
    const blob = new Blob([qr], { type: "image/svg+xml" });
    downloadBlob(blob, `web-store-qr.svg`);
  }, []);

  // const handleClick = (event) => {
  //   hiddenFileInput.current.click();
  // };

  // // Call a function (passed as a prop from the parent component)
  // // to handle the user-selected file
  // const VendingImport = async (event) => {
  //   const fileUploaded = event.target.files[0];
  //   // props.handleFile(fileUploaded);
  //   console.log(fileUploaded)
  //   const data = await fileUploaded.arrayBuffer();
  //   const workbook = XLSX.readFile(data, { sheetRows: 5 });
  //   console.log({ workbook })

  //   const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //   let jsonData = XLSX.utils.sheet_to_json(worksheet);

  //   jsonData = jsonData.filter(item => {
  //     if (item.product_name && item.product_name && item.product_name) {
  //       return item;
  //     }
  //   })
  //   console.log({ jsonData })
  //   const errors = []
  //
  //   jsonData = jsonData.filter((item, index) => {
  //     if (item.NAME && SerialValidation(item['SERIAL NUMBER'])) {
  //       return item;
  //     } else if (!SerialValidation(item['SERIAL NUMBER'])) {
  //       errors.push(`Row ${index + 1} : Invalid Serial Number: ${item['SERIAL NUMBER']}`)
  //     } else {
  //       errors.push(`Row ${index + 1} : Invalid Name: ${item['NAME']}`)
  //     }
  //   })
  //   console.log({ jsonData });
  //   if (errors.length) {
  //     setImportError(errors);
  //     setIsInfo(true);
  //   } else {
  //     setIsInfo(false)
  //   }

  //   window.axiosIns
  //     .post(`/products/bulk/upload/`, jsonData)
  //     .then(() => {
  //       enqueueSnackbar("Vending Machines Added successfully.");
  //     })
  //     .catch((err) => {
  //       if (err.response.data.detail) {
  //         enqueueSnackbar(err.response.data.detail);
  //       } else {
  //         handleServerErrors(
  //           err,
  //           enqueueSnackbar,
  //           "Could not add vending machines. Try again."
  //         );
  //       }
  //     })
  //     .then(() => {
  //       getModules();
  //       setModulesSelected([]);
  //     });

  // };

  // const hiddenFileInput = React.useRef(null);

  const closeOperationsMenu = () => {
    setShowOperationsMenu(null);
  };

  const onHardwareResetPressed = async () => {
    setShowSubmitLoader(true);
    let fd = new FormData();
    fd.append("action", "resetHardware");
    fd.append("device_id", modulesSelected[0].oro_id);
    try {
      setShowOperationsMenu(null);
      await window.axiosIns.post("manage_device/reset_device", fd);
      setResetHardwareModal(false);
      enqueueSnackbar("Hardware Reset Successful.");
      getModules();
    } catch (err) {
      return handleServerErrors(err, enqueueSnackbar);
    } finally {
      setShowSubmitLoader(false);
    }
  }

  const onEditButtonClick = () => {
    if (modulesSelected?.length === 1) {
      if (
        (modulesSelected[0] || {}).age_verification_method ===
        "disabled"
      ) {
        setShowScannerFields(false);
        setShowYotiFields(false);
      } else if (
        (modulesSelected[0] || {}).age_verification_method ===
        "id_scanner"
      ) {
        setShowScannerFields(true);
        setShowYotiFields(false);
      } else if (
        (modulesSelected[0] || {}).age_verification_method === "yoti"
      ) {
        setShowScannerFields(false);
        setShowYotiFields(true);
      }
      setEditModal(true);
      setTypeSelection((modulesSelected[0] || {}).device_type_id);
    } else {
      setBulkEditModal(true);
    }
  }

  const disableEditButton = () => {
    if (enableTaxes) {
      if (modulesSelected.length <= 0 || props.currentUser.type === "SU") {
        return true;
      }
    } else {
      if (modulesSelected.length !== 1 || props.currentUser.type === "SU") {
        return true;
      }
    }
    return false;
  }

  const getAppName = (name) => {
    try {
      const firstChar = name?.charAt(0).toUpperCase();
      const remainingChars = name?.slice(1, -2);
      const appName = firstChar + remainingChars;
      return `${appName?.replaceAll("_", " ")}: `;
    } catch (error) {
      return name || "";
    }
  }

  const get_disabled = () =>{
    let selected_module = (modulesSelected[0] || {})
    return (
      selected_module?.status === "Not Connected"
        ? true
        : modulesSelected.length === 0 ||
          props.currentUser.type === "SU" || selected_module?.approval_status !== "Approved"
    )
  }

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title={hostConfig?.["custom_sidebar_labels"]?.["vending-machines"] ?? 'Vending Machines'}
        description="All devices that are manufactured automatically show up here.  You can assign these devices to a customer here (or unassign a device from a customer)."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            disabled={props.currentUser.type === "SU"}
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
          />
          <AllocateButton
            disabled={
              modulesSelected.length !== 1 || props.currentUser.type === "SU"
            }
            className="mr-3"
            label="Allocate"
            onClick={() => {
              setAllocateModal(true);
              setTypeSelection((modulesSelected[0] || {}).device_type_id);
            }}
          />
          <EditButton
            disabled={disableEditButton()}
            className="mr-3"
            label="Edit"
            onClick={onEditButtonClick}
          />
          <DeleteButton
            disabled={
              modulesSelected.length === 0 || props.currentUser.type === "SU"
            }
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          <StatusUpdateButton
            disabled= {get_disabled()}
            className="mr-3"
            label="Update Status"
            onClick={() => setWorkingStatusModal(true)}
          />
          {  props.currentUser.type === "SA"?
          <StatusUpdateButton
            disabled={modulesSelected.length === 0 ||
                  props.currentUser.type === "SU"
            }
            className="mr-3"
            label="Update Approval Status"
            onClick={() => setApprovalStatusModal(true)}
          />:<></>}
          <Tooltip title={
            modulesSelected.length === 1 && modulesSelected[0]?.status != "Connected" && modulesSelected[0]?.status != true
              ? "Device is offline." : ""}>
            <div>
              <SettingsButton
                disabled={
                  modulesSelected.length != 1 || props.currentUser.type === "SU"
                  || (((modulesSelected[0] || {}).status === "Connected") ||
                    ((modulesSelected[0] || {}).status === true) &&
                    modulesSelected.length === 1
                    ? false
                    : true)
                }
                className="mr-3"
                onClick={(event) => setShowOperationsMenu(event.currentTarget)}
                label="Operations"
                variant="outlined"
              />
            </div>
          </Tooltip>
               
          <RefreshButton
            className="mr-3"
            label="Refresh"
            onClick={() => {
              setModulesSelected([]);
              getModules();
            }}
          />

          <ExportButton
            disabled={props.currentUser.type === "SU"}
            className="mr-3"
            label="Export"
            onClick={() => vmExport()}
          />
          {/* <>
            <ImportButton
              className="mr-3"
              label="Import"
              onClick={handleClick}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={VendingImport}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </> */}

          <Menu
            anchorEl={showOperationsMenu}
            keepMounted
            open={Boolean(showOperationsMenu)}
            onClose={closeOperationsMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom" }}
          >
            <MenuItem
              disabled={
                ((modulesSelected[0] || {}).status === "Connected") |
                  ((modulesSelected[0] || {}).status === true) &&
                  modulesSelected.length === 1
                  ? false
                  : true
              }
              onClick={() => {
                setShowOperationsMenu(null);
                setResetHardwareModal(true);
              }}
            >
              Hardware Reset
            </MenuItem>
            <MenuItem
              disabled={
                ((modulesSelected[0] || {}).status === "Connected") |
                  ((modulesSelected[0] || {}).status === true) &&
                  modulesSelected.length === 1
                  ? false
                  : true
              }
              onClick={
                ((modulesSelected[0] || {}).status === "Connected") || ((modulesSelected[0] || {}).status === true)
                  ? () => {
                    setShowOperationsMenu(null);
                    setRebootModal(true);
                    setClickedDevice(modulesSelected[0] || {});
                  }
                  : ""
              }
            >
              Reboot
            </MenuItem>
          </Menu>
        </div>
        <div className="d-flex" style={{ width: "200px" }}>
          <SearchBox
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={sensorsFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={props.currentUser.type === "SA" ? fields : operatorFields}
          data={modulesList}
          loader={loader}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getModules(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          onRowPerPageChange={(rows) => {
            getModules(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          backendPagination={true}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
        <CrudDialog
          title="Add Vending Machine"
          okText="Add VM"
          fields={props.currentUser.type === "SA" ? adfields : daFields}
          crudLoader={crudLoader}
          description="Please fill in the details below."
          values={{ tax_percentage: "0.0000" }}
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => {
            setAddModal(false);
            setShowScannerFields(false);
            setShowYotiFields(false);
          }}
          onFieldChange={(field, value, fieldValues) => {
            handleTypeSelection(field, value);
            if (field.key === "age_verification_method" && value === "yoti") {
              setShowYotiFields(true);
              setShowScannerFields(false);
            } else if (
              field.key === "age_verification_method" &&
              value === "id_scanner"
            ) {
              setShowYotiFields(false);
              setShowScannerFields(true);
            } else if (
              field.key === "age_verification_method" &&
              value === "disabled"
            ) {
              setShowYotiFields(false);
              setShowScannerFields(false);
            }
          }}
          showSubmitActionLoader={showSubmitLoader}
        />

        <CrudDialog
          title="Update Working Status"
          okText="Update"
          fields={
            props.currentUser.type === "SA"
              ? WorkingStatusFields
              : WorkingStatusFields
          }
          values={{
            working_status:
              (modulesSelected?.[0] || {})?.working_status === "outOfService"
                ? "outOfService"
                : "inService",
          }}
          onFieldChange={handleTypeSelection}
          description="Update working status based on current state."
          onSubmit={(values, hasErrors) => {
            values.working_status === undefined
              ? (values.working_status = "inService")
              : (values.working_status = values.working_status);
            handleWorkingStatus(values, modulesSelected);
          }}
          open={workingStatusModal}
          onClose={() => setWorkingStatusModal(false)}
        />
        <CrudDialog
          title="Update Status"
          okText="Update"
          fields={approveStatusFields}
          description="Update status for Operator(s)."
          values={{
            approval_status:
            (modulesSelected?.[0] || {})?.approval_status 
          }}
          onSubmit={(values) => {
            updateApprovalStatus(values);
          }}
          open={operatorStatusModal}
          onClose={() => setApprovalStatusModal(false)}
        />
        <CrudDialog
          title="Allocate"
          okText="Allocate"
          onFieldChange={handleTypeSelection}
          description="Please Allocate the details below."
          fields={allocateFields}
          values={{ ...modulesSelected[0], device_type: (modulesSelected[0] || {}).device_type_id }}
          onSubmit={(values, hasErrors) => {
            handleAllocate(values);
          }}
          // extraButtonText="Allocate"
          hide={hideAllocateModal}
          onExtraButton={() => {
            // fetchDevicePasscode()
            // setPasscodeModal(true);
            setHideAllocateModal(true);
          }}
          open={allocateModal}
          onClose={() => setAllocateModal(false)}
          showSubmitActionLoader={showSubmitLoader}
        />
        <CrudDialog
          title="Edit Device"
          okText="Save"
          description="Please edit the details below."
          crudLoader={crudLoader}
          fields={props.currentUser.type === "SA" ? adfields : editdaFields}
          values={{
            ...modulesSelected[0],
            device_type: (modulesSelected[0] || {}).device_type_id,
            tax_percentage: taxFormatter(modulesSelected[0]?.tax_percentage)
          }}
          onSubmit={(values) => {
            if (props.currentUser.type === "SA") {
              values.scanner_license = null;
            }
            if (values.scanner_license === undefined) {
              values.scanner_license = null;
            }
            handleEdit(values);
          }}
          extraComponent={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                marginLeft: "0.5rem",
              }}
            >
              <div ref={qrRef}>
                <QRCode
                  size={130}
                  value={`${window.location.origin}/webstore/products?serial_no=${modulesSelected[0]?.serial_number}`}
                />
              </div>
              <span>
                <IconButton color="primary" onClick={handleQrDownload}>
                  <GetApp />
                </IconButton>
              </span>
            </div>
          }
          hide={hideEditModal}
          open={editModal}
          onClose={() => {
            setEditModal(false);
            setShowScannerFields(false);
            setShowYotiFields(false);
          }}
          onFieldChange={(field, value, fieldValues) => {
            handleTypeSelection(field, value);
            if (field.key === "age_verification_method" && value === "yoti") {
              setShowYotiFields(true);
              setShowScannerFields(false);
            } else if (
              field.key === "age_verification_method" &&
              value === "id_scanner"
            ) {
              setShowYotiFields(false);
              setShowScannerFields(true);
            } else if (
              field.key === "age_verification_method" &&
              value === "disabled"
            ) {
              setShowYotiFields(false);
              setShowScannerFields(false);
            }
          }}
          onOpen={(props) => {
            if (props?.device_type == mSeriesID) {	
               setMSeriesSelected(true);	
            } else {
              setMSeriesSelected(false);
            }
           }}
           showSubmitActionLoader={showSubmitLoader}
        />
        <CrudDialog
          title="Update Taxes"
          okText="Update"
          description="Please update the details below."
          fields={bulkEditFields}
          values={{ tax_percentage: "0.0000" }}
          onSubmit={(values) => {
            handleBulkEdit(values);
          }}
          open={bulkEditModal}
          onClose={() => {
            setBulkEditModal(false);
          }}
          showSubmitActionLoader={showSubmitLoader}
        />
        <CrudDialog
          title="Device Passcode"
          okText="Go Back"
          description="To reset password, click on 'reset password' button."
          fields={passcodeFields}
          values={{ passcode: devicePasscode }}
          onSubmit={(values, hasErrors) => {
            setHideEditModal(false);
            setPasscodeModal(false);
            setDevicePasscode("");
          }}
          extraButtonText="Reset Passcode"
          onExtraButton={() => {
            resetPasscode();
          }}
          extraButtonLoading={resetPasscodeLoader}
          onClose={() => {
            setDevicePasscode("");
            setPasscodeModal(false);
            setEditModal(false);
            setTimeout(() => {
              // forcing the modal to change it's hide state to false on the next tick of event loop
              // to prevent flickering motion
              setHideEditModal(false);
            }, 0);
          }}
          open={passcodeModal}
        />
        <CrudDialog
          title="Delete Device(s)"
          description="Are you sure you want to delete the Device(s)?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          showSubmitActionLoader={showSubmitLoader}
        />
        <CrudDialog
          title="Reboot Device"
          description="Are you sure you want to reboot this device?"
          okText="Reboot"
          onSubmit={() => rebootDevice()}
          open={rebootModal}
          onClose={() => {
            setRebootModal(false);
            setClickedDevice({});
          }}
          showSubmitActionLoader={showSubmitLoader}
        />
         <CrudDialog
          title="Hardware Reset"
          description="Are you sure you want to reset hardware?"
          fields={[
            {
              type: "warning",
              warning:
                "Multiple attempts to recover a vending machine may cause HARDWARE DAMAGE",
              visible: true,
            },
          ]}
          okText="Reset"
          onSubmit={() => onHardwareResetPressed()}
          open={resetHardwareModal}
          onClose={() => {
            setResetHardwareModal(false);
          }}
          showSubmitActionLoader={showSubmitLoader}
        />
        {/* display versions */}
        <CrudDialog
          title="Versions Detail"
          cancelText="Ok"
          description={
            <div style={{ marginTop: "10px" }}>
              {
                Object.entries(versionData).map(([key, value]) => 
                  <div style={{ marginTop: "10px" }}>
                     <b>{getAppName(key)}</b>
                      {value}
                  </div>
                )
              }
            </div>
          }
          okText={false}
          open={verisonClick}
          onClose={() => setVerisonClick(false)}
        />
        <CrudDialog
          onRef={(e) => {
            advanceFilterCD = e;
          }}
          title="Advance Filters"
          description="Advance Filters"
          okText="Filter"
          fields={advanceFilterFields}
          onSubmit={applyAdvancedSearch}
          open={advanceFilterModal}
          values={advanceFilterParams}
          onClose={() => {
            setAdvanceFilterModal(false);
            advanceFilterCD.setState({ fieldValues: {} });
            setAdvanceFilterParams({});
          }}
          extraButtonText="Clear Filters"
          onExtraButton={() => clearAdvanceFilter()}
        />
        <InfoModal 
          title="Vending Machine Errors"
          data={importError || []}
          open={isInfo}
          close={() => {
            setIsInfo(false) 
            setImportError([])  
            }
          }
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
    host: state.userReducer.host,
  };
};

export default connect(mapStateToProps)(withTheme(Sensors));
